<template>
  <SideBar />

  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 class="page-title">الرئيسية</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="row row-cards">
            <div class="col-12">
              <div class="mb-2 flex-grow-1">
                <form
                  v-if="!ismultiSearch"
                  v-on:submit.prevent="searchForm"
                  id="single-search"
                >
                  <div class="input-group input-group-flat" @click.prevent="searchForm">
                    <span class="input-group-text bg-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <circle cx="10" cy="10" r="7"></circle>
                        <line x1="21" y1="21" x2="15" y2="15"></line>
                      </svg>
                    </span>
                    <input
                      autocomplete="off"
                      v-model="searchTerm"
                      type="text"
                      class="form-control form-control-lg"
                      :placeholder="
                        role == 5 ? 'البحث برقم العينة...' : 'بحث بالاسم أو برقم الجواز…'
                      "
                      :aria-label="
                        role == 5
                          ? 'البحث برقم العينة...'
                          : 'البحث بالاسم أو برقم الجواز…'
                      "
                    />

                    <span v-if="role != 5" class="input-group-text">
                      <a
                        href="#"
                        @click.prevent="toggleInputs"
                        class="link-secondary ms-2"
                        data-bs-toggle="tooltip"
                        aria-label="تحويل لبحث متعدد"
                        data-bs-original-title="تحويل لبحث متعدد"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-line-height"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 8l3 -3l3 3" />
                          <path d="M3 16l3 3l3 -3" />
                          <path d="M6 5l0 14" />
                          <path d="M13 6l7 0" />
                          <path d="M13 12l7 0" />
                          <path d="M13 18l7 0" />
                        </svg>
                      </a>
                    </span>
                  </div>
                </form>

                <form v-else id="multi-search">
                  <div class="input-group input-group-flat" id="">
                    <span class="input-group-text bg-white" @click.prevent="searchForm">
                      <a
                        href="#"
                        class="link-secondary ms-2"
                        data-bs-toggle="tooltip"
                        aria-label="بحث"
                        data-bs-original-title="بحث"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <circle cx="10" cy="10" r="7"></circle>
                          <line x1="21" y1="21" x2="15" y2="15"></line>
                        </svg>
                      </a>
                    </span>
                    <textarea
                      v-model="searchTerms"
                      class="form-control form-control-lg"
                      rows="4"
                      placeholder="بحث متعدد بأرقام الجوازات…"
                      aria-label="بحث متعدد بأرقام الجوازات…"
                    ></textarea>
                    <span class="input-group-text">
                      <a
                        href="#"
                        @click.prevent="toggleInputs"
                        class="link-secondary ms-2"
                        data-bs-toggle="tooltip"
                        aria-label="تحويل للبحث العادي"
                        data-bs-original-title="تحويل للبحث العادي"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-line-height"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 8l3 -3l3 3" />
                          <path d="M3 16l3 3l3 -3" />
                          <path d="M6 5l0 14" />
                          <path d="M13 6l7 0" />
                          <path d="M13 12l7 0" />
                          <path d="M13 18l7 0" />
                        </svg>
                      </a>
                    </span>
                  </div>
                </form>
              </div>
            </div>

            <div class="hr mb-1"></div>

            <div class="col-12">
              <div class="row row-cards">
                <div class="col-sm-6 col-lg-3">
                  <div class="card card-sm">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <span class="bg-blue text-white avatar">
                            <!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-users"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <circle cx="9" cy="7" r="4"></circle>
                              <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                              <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                            </svg>
                          </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium font-lg">
                            {{ formatNumber(client_count) }}
                          </div>
                          <div class="text-muted">عدد العملاء</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="card card-sm">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <span class="bg-green text-white avatar">
                            <!-- Download SVG icon from http://tabler-icons.io/i/shopping-cart -->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-test-pipe"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path
                                d="M20 8.04l-12.122 12.124a2.857 2.857 0 1 1 -4.041 -4.04l12.122 -12.124"
                              ></path>
                              <path d="M7 13h8"></path>
                              <path d="M19 15l1.5 1.6a2 2 0 1 1 -3 0l1.5 -1.6z"></path>
                              <path d="M15 3l6 6"></path>
                            </svg>
                          </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">
                            {{ formatNumber(sample_count) }}
                          </div>
                          <div class="text-muted">عدد العينات</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="card card-sm">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <span class="bg-twitter text-white avatar">
                            <!-- Download SVG icon from http://tabler-icons.io/i/brand-twitter -->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-file-analytics"
                              width="44"
                              height="44"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#ffffff"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path
                                d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                              />
                              <path d="M9 17l0 -5" />
                              <path d="M12 17l0 -1" />
                              <path d="M15 17l0 -3" />
                            </svg>
                          </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">
                            {{ formatNumber(test_count) }}
                          </div>
                          <div class="text-muted">عدد الفحوصات</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                  <div class="card card-sm">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <span class="bg-twitter text-white avatar">
                            <!-- Download SVG icon from http://tabler-icons.io/i/brand-twitter -->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-certificate"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <circle cx="15" cy="15" r="3"></circle>
                              <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path>
                              <path
                                d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73"
                              ></path>
                              <line x1="6" y1="9" x2="18" y2="9"></line>
                              <line x1="6" y1="12" x2="9" y2="12"></line>
                              <line x1="6" y1="15" x2="8" y2="15"></line>
                            </svg>
                          </span>
                        </div>
                        <div class="col">
                          <div class="font-weight-medium">
                            {{ formatNumber(report_count) }}
                          </div>
                          <div class="text-muted">عدد التقارير الصادرة</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row row-cards">
                    <div
                      v-if="role == 1 || role == 5"
                      :class="['col-sm-12', { 'col-lg-6': role === 1 }]"
                    >
                      <div class="card">
                        <div class="card-body">
                          <h3 class="card-title">العملاء في انتظار نتيجة الفحص</h3>

                          <div class="table-responsive" ref="scrollContainerTestReport">
                            <table
                              class="table card-table table-vcenter text-nowrap datatable table-striped table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>
                                    اسم العميل
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="icon icon-sm text-dark icon-thick"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      stroke-width="2"
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <polyline points="6 15 12 9 18 15"></polyline>
                                    </svg>
                                  </th>
                                  <th class="text-center">رقم الجواز</th>
                                  <th class="text-center">تاريخ</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <infinite-scroll
                                api-endpoint="/api/client-without-testreport/"
                                :container="$refs.scrollContainerTestReport"
                              >
                                <template v-slot="{ item }">
                                  <tr
                                    class="cursor-pointer"
                                    @click="
                                      $router.push({
                                        name: 'detail_client',
                                        params: { id: item.id },
                                      })
                                    "
                                  >
                                    <td>
                                      <span class="avatar avatar-sm mx-2">
                                        <img :src="item.photo" />
                                      </span>
                                      {{ item.name }}
                                    </td>
                                    <td class="text-center">
                                      {{ item.passport_no }}
                                    </td>
                                    <td class="text-center">
                                      {{ get_date(item.created) }}
                                    </td>
                                  </tr>
                                </template>
                              </infinite-scroll>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="role == 1 || role == 4"
                      :class="['col-sm-12', { 'col-lg-6': role === 1 }]"
                    >
                      <div class="card">
                        <div class="card-body">
                          <h3 class="card-title">العملاء في انتظار سحب عينة الدم</h3>

                          <div class="table-responsive" ref="scrollContainer">
                            <table
                              class="table card-table table-vcenter text-nowrap datatable table-striped table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>
                                    اسم العميل
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="icon icon-sm text-dark icon-thick"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      stroke-width="2"
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <polyline points="6 15 12 9 18 15"></polyline>
                                    </svg>
                                  </th>
                                  <th class="text-center">رقم الجواز</th>
                                  <th class="text-center">تاريخ</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <infinite-scroll
                                api-endpoint="/api/client-without-bloodtest/"
                                :container="$refs.scrollContainer"
                              >
                                <template v-slot="{ item }">
                                  <tr
                                    class="cursor-pointer"
                                    @click="
                                      $router.push({
                                        name: 'detail_client',
                                        params: { id: item.id },
                                      })
                                    "
                                  >
                                    <td>
                                      <span class="avatar avatar-sm mx-2">
                                        <img :src="item.photo" />
                                      </span>
                                      {{ item.name }}
                                    </td>
                                    <td class="text-center">
                                      {{ item.passport_no }}
                                    </td>
                                    <td class="text-center">
                                      {{ get_date(item.created) }}
                                    </td>
                                  </tr>
                                </template>
                              </infinite-scroll>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <h3 class="card-title">الإحصائيات (آخر 30 يوم)</h3>
                      <div>
                        <ClientsChart />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";
import InfiniteScroll from "@/components/InfiniteScroll.vue";
import { get_date } from "@/script.js";
import ClientsChart from "@/components/ClientsChart.vue";

export default {
  components: {
    FooterBar,
    SideBar,
    InfiniteScroll,
    ClientsChart,
  },
  setup() {
    // Accessing axios from global properties
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // now you can use axios

    // Reactive state
    const client_count = ref("");
    const test_count = ref("");
    const report_count = ref("");
    const sample_count = ref("");
    const searchTerm = ref("");
    const searchTerms = ref("");
    const ismultiSearch = ref(false);

    // Computed properties
    const role = computed(() => {
      return proxy.$store.getters.getUser.role;
    });

    // Methods
    const toggleInputs = () => {
      ismultiSearch.value = !ismultiSearch.value;
    };

    const formatNumber = (number) => {
      return new Intl.NumberFormat("en-US").format(number);
    };

    const searchForm = () => {
      if (searchTerm.value && !ismultiSearch.value) {
        if (role.value === "5") {
          proxy.$router.push(`/sample/?search=${searchTerm.value}`);
        } else {
          proxy.$router.push(`/client/?search=${searchTerm.value}`);
        }
      } else if (searchTerms.value && ismultiSearch.value) {
        const termsArray = searchTerms.value
          .split(/[\s\n]+/)
          .filter((term) => term.trim() !== "");
        const jsonSearchTerms = JSON.stringify(termsArray);
        const encodedSearchTerms = encodeURIComponent(jsonSearchTerms);
        proxy.$router.push(`/client/?search=${encodedSearchTerms}`);
      }
    };

    const getData = async () => {
      const all_counts_response = await axios.get("/api/all-counts/");
      client_count.value = all_counts_response?.data.clients;
      test_count.value = all_counts_response?.data.tests;
      report_count.value = all_counts_response?.data.report_issues;
      sample_count.value = all_counts_response?.data.blood_samples;
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(() => {
      getData();
    });

    // Returning state, computed properties, and methods to the template
    return {
      get_date,
      client_count,
      test_count,
      report_count,
      sample_count,
      searchTerm,
      searchTerms,
      ismultiSearch,
      role,
      toggleInputs,
      formatNumber,
      searchForm,
    };
  },
};
</script>

<style scoped>
.table-responsive {
  overflow: auto;
  max-height: 300px;
}

.table-responsive td {
  max-width: 205px;
  overflow-x: auto;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios'
import router from './router'
import store from './store'
import i18n from './i18n'
import Notifications, { notify } from '@kyvg/vue3-notification'

import VueClickAway from "vue3-click-away";

// Set up default axios headers
axios.defaults.headers.common['Accept-Language'] = `AR`;

if (store.state.token) {
    axios.defaults.headers.common['Authorization'] = `token ${store.state.token}`;
}

// Helper function to format error messages
function formatErrorMessage(errorData) {
    if (!errorData) return "حدث خطأ ما!";
    return Object.entries(errorData)
        .map(([key, value]) => {
            if (key === 'detail') {
                // Only return the value for 'detail' key
                return `${value}`;
            } else {
                return `${key}: ${value}`;
            }
        })
        .join('<hr>');
}

// Axios request interceptor
axios.interceptors.request.use(
    config => {
        store.dispatch('setLoading', true);
        store.dispatch('http_error', {});
        return config;
    },
    error => {
        store.dispatch('setLoading', false);  // Hide loader on error
        return Promise.reject(error);
    }
);

// Axios response interceptor
axios.interceptors.response.use(
    response => {
        store.dispatch('setLoading', false);  // Hide loader on response
        return response;
    },
    error => {
        store.dispatch('setLoading', false);
        if (error.response.status === 401) {
            axios.defaults.headers.common['Authorization'] = "";
            store.dispatch('logout');
            router.push('/login/');
        } else if (error.response.status === 403) {
            store.dispatch('http_error', { error });
            notify({
                text: "غير مصرح لك!",
                type: 'error',
            });
            axios.defaults.headers.common['Authorization'] = "";
            store.dispatch('logout');
            router.push('/login/');
        } else {
            store.dispatch('http_error', { error });
            let msg = formatErrorMessage(error.response?.data);

            // Show notification with error message
            notify({
                text: msg,
                type: 'error',
            });
        }
    }
);

// Set base URL for axios based on environment
axios.defaults.baseURL = "https://api.sabertests.com";
if (process.env.NODE_ENV === 'development') axios.defaults.baseURL = "http://localhost:8000";

// Create Vue app instance
const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(Notifications)
    .use(VueClickAway);

app.config.globalProperties.axios = axios;

// Mount Vue app
app.mount('#app');

<template>
  <SideBar />
  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 class="page-title">عرض عميل</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="row row-cards">
            <div class="col-12 col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">نظرة عامة حول العميل</h4>
                  <div class="btn-list flex-nowrap card-actions">
                    <button
                      v-if="role == 1 || role == 3"
                      @click="
                        $router.push({ name: 'edit_client', params: { id: client.id } })
                      "
                      class="btn d-sm-inline-block"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-edit"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                        ></path>
                        <path
                          d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                        ></path>
                        <path d="M16 5l3 3"></path>
                      </svg>
                      <span class="mx-1">تعديل</span>
                    </button>
                    <button
                      v-if="role == 1"
                      @click="
                        $router.push({
                          name: 'moderations_object',
                          params: { content_type_id: 5, object_id: client.id },
                        })
                      "
                      class="btn d-sm-inline-block"
                    >
                      <!-- Download SVG icon from http://tabler-icons.io/i/history -->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="12 8 12 12 14 14" />
                        <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
                      </svg>
                      <span class="mx-1">تاريخ التعديل</span>
                    </button>

                    <template v-if="client.status == 1">
                      <button
                        v-if="role == 1 || role == 5"
                        @click="openModal(client.id)"
                        class="btn btn-danger d-sm-inline-block"
                      >
                        <span class="mx-1">حظر</span>
                      </button>
                    </template>
                  </div>
                </div>
                <div class="card-body">
                  <div v-if="client?.status == 0" class="row">
                    <div class="col-12">
                      <div class="alert alert-danger alert-dismissible" role="alert">
                        <div class="d-flex">
                          <div>
                            <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                            <!-- SVG icon code with class="alert-icon" -->
                          </div>
                          <div>
                            <h4 class="alert-title">محظور</h4>
                            <div class="text-muted">هذا العميل تم حظره!</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="client?.moderation_status == 0" class="row">
                    <div class="col-12">
                      <div class="alert alert-info alert-dismissible" role="alert">
                        <div class="d-flex">
                          <div>
                            <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                            <!-- SVG icon code with class="alert-icon" -->
                          </div>
                          <div>
                            <h4 class="alert-title">تحت الموافقة</h4>
                            <div class="text-muted">
                              هذا العنصر لازال يتطلب موافقة المدير على التعديلات
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="card-body p-1 text-center">
                          <span class="avatar avatar-xl mb-3">
                            <img
                              :src="client.photo"
                              style="max-width: 2.5cm; max-height: 2.5cm"
                            />
                          </span>
                          <h3 class="m-0 mb-3">{{ client.name }}</h3>
                        </div>
                      </div>
                      <div class="row divide-y">
                        <br />
                        <div class="col-3">
                          <label class="form-label">تاريخ الميلاد</label>
                          <div class="form-control-plaintext">{{ client.dob }}</div>
                        </div>
                        <div class="col-3">
                          <label class="form-label">الجنسية</label>
                          <div class="form-control-plaintext">
                            {{ get_nationality(client.nationality) }}
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="form-label">المهنة</label>
                          <div class="form-control-plaintext">
                            {{ client.occupation }}
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="form-label">الحالة الاجتماعية</label>
                          <div class="form-control-plaintext">
                            {{ get_marital_status(client.marital_status) }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="row divide-y">
                        <br />
                        <div class="col-3">
                          <label class="form-label">رقم الهاتف</label>
                          <div class="form-control-plaintext">{{ client.mobile }}</div>
                        </div>

                        <div class="col-3">
                          <label class="form-label">رقم الجواز</label>
                          <div class="form-control-plaintext">
                            {{ client.passport_no }}
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="form-label">مكان الإصدار</label>
                          <div class="form-control-plaintext">
                            {{ client.place_of_issue }}
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="form-label">تاريخ الإصدار</label>
                          <div class="form-control-plaintext">
                            {{ client.date_of_issue }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="row divide-y">
                        <br />
                        <div class="col-4">
                          <label class="form-label"> طول العميل </label>
                          <div class="form-control-plaintext">
                            <template v-if="client.height"
                              >{{ client.height }} سم</template
                            >
                            <template v-else>-</template>
                          </div>
                        </div>
                        <div class="col-8">
                          <label class="form-label">تاريخ آخر فحص</label>
                          <div class="form-control-plaintext">
                            {{ get_last_date_tests() || "-" }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="client.notes" class="col-12">
                      <div class="row divide-y">
                        <br />
                        <div class="col-12">
                          <label class="form-label"> ملاحظات</label>
                          <div class="form-control-plaintext">
                            {{ client.notes || "-" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">بيانات المكتب</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <br />
                        <div class="col-4">
                          <label class="form-label">رقم الطلب</label>
                          <div class="form-control-plaintext">
                            {{ client.application_no || "-" }}
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="form-label">رقم الخطاب</label>
                          <div class="form-control-plaintext">
                            {{ client.letter_no || "-" }}
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="form-label">تاريخ الخطاب</label>
                          <div class="form-control-plaintext">
                            {{ get_letter_date() || "-" }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="row divide-y">
                        <br />
                        <div class="col-6">
                          <label class="form-label"> اسم المكتب</label>
                          <div class="form-control-plaintext">
                            {{ client.office_name || "-" }}
                          </div>
                        </div>
                        <div class="col-6">
                          <label class="form-label"> اسم المندوب</label>
                          <div class="form-control-plaintext">
                            {{ client.agent_name || "-" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <InternalNote :clientID="clientID"></InternalNote>
              <br />
              <div v-if="role == 1 || role == 5 || role == 4" class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">عينات الدم</h4>
                    <div class="card-actions">
                      <button
                        v-if="role == 1 || role == 4"
                        @click="
                          $router.push({
                            name: 'height_client',
                            params: { id: client.id },
                          })
                        "
                        class="btn-sm btn btn-primary"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        عينة دم جديدة
                      </button>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="table-responsive">
                          <table
                            class="table table-responsive card-table table-vcenter text-nowrap table-hover"
                          >
                            <thead>
                              <tr>
                                <th class="w-1">#</th>
                                <th>تاريخ العينة</th>
                                <th class="text-center">كود العينة</th>
                                <th class="text-center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="height_blood_sample in height_blood_samples"
                                :key="height_blood_sample.blood_test_uid"
                              >
                                <td>
                                  <span class="text-muted">{{
                                    height_blood_sample.id
                                  }}</span>
                                </td>
                                <td class="text-center">
                                  {{ height_blood_sample.created }}
                                </td>
                                <td class="text-center">
                                  {{ height_blood_sample.blood_test_uid }}
                                </td>

                                <td class="text-end">
                                  <button
                                    v-if="
                                      height_blood_sample.has_report == 0 &&
                                      (role == 1 || role == 5)
                                    "
                                    @click="
                                      $router.push({
                                        name: 'add_report_for_blood_sample',
                                        params: {
                                          height_blood_sample_uid:
                                            height_blood_sample.blood_test_uid,
                                        },
                                      })
                                    "
                                    class="btn-sm btn btn-primary"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="icon"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      stroke-width="2"
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <line x1="12" y1="5" x2="12" y2="19"></line>
                                      <line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>
                                    إضافة فحص
                                  </button>
                                  &nbsp;
                                  <button
                                    @click="open_sample_code(height_blood_sample)"
                                    class="btn-sm btn btn-primary"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="icon icon-tabler icon-tabler-test-pipe-2"
                                      width="44"
                                      height="44"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="#ffffff"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <path d="M15 3v15a3 3 0 0 1 -6 0v-15"></path>
                                      <path d="M9 12h6"></path>
                                      <path d="M8 3h8"></path>
                                    </svg>
                                    كود العينة
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div v-if="role == 1 || role == 5" class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">فحوصات العميل</h4>
                  </div>
                  <div class="card-body p-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="table-responsive overflow-visible">
                          <table
                            class="table table-responsive card-table table-vcenter text-nowrap table-hover"
                          >
                            <thead>
                              <tr>
                                <th class="w-1">#</th>
                                <th>تاريخ الفحص</th>
                                <th class="text-center">كود العينة</th>

                                <th class="text-center">التقارير الصادرة</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="report in reports" :key="report.report_uid">
                                <td>
                                  <span class="text-muted">
                                    <small>
                                      <span
                                        v-if="report.moderation_status == 0"
                                        class="badge rounded-pill bg-warning"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="icon"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="2"
                                          stroke="currentColor"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <circle cx="12" cy="12" r="9" />
                                          <line x1="9" y1="12" x2="15" y2="12" />
                                        </svg>
                                      </span>
                                      <span v-else class="badge rounded-pill bg-green">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="icon"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="2"
                                          stroke="currentColor"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <circle cx="12" cy="12" r="9" />
                                          <path d="M9 12l2 2l4 -4" />
                                        </svg>
                                      </span>
                                    </small>
                                  </span>
                                </td>
                                <td class="text-center">{{ report.report_date }}</td>
                                <td class="text-center">
                                  {{ report.sample_data.blood_test_uid }}
                                </td>

                                <td class="text-center">
                                  <!-- dropdown -->
                                  <DropDown :count="report.reports_issues?.length">
                                    <h6 class="dropdown-header">قائمة التقارير</h6>
                                    <a
                                      v-for="issue in report.reports_issues"
                                      @click="open_report_issue(issue)"
                                      href="#"
                                      :key="issue.id"
                                      class="dropdown-item"
                                    >
                                      <svg
                                        v-if="issue.status == 1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler text-green icon-tabler-printer"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path
                                          d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"
                                        ></path>
                                        <path
                                          d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"
                                        ></path>
                                        <rect
                                          x="7"
                                          y="13"
                                          width="10"
                                          height="8"
                                          rx="2"
                                        ></rect>
                                      </svg>
                                      <svg
                                        v-else
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler text-red icon-tabler-printer"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path
                                          d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"
                                        ></path>
                                        <path
                                          d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"
                                        ></path>
                                        <rect
                                          x="7"
                                          y="13"
                                          width="10"
                                          height="8"
                                          rx="2"
                                        ></rect>
                                      </svg>
                                      <span class="mx-2">تقرير {{ issue.created }} </span>
                                    </a>

                                    <div class="dropdown-divider"></div>
                                    <button
                                      @click="creat_new_issue(report)"
                                      class="dropdown-item"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-certificate"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <circle cx="15" cy="15" r="3"></circle>
                                        <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path>
                                        <path
                                          d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73"
                                        ></path>
                                        <line x1="6" y1="9" x2="18" y2="9"></line>
                                        <line x1="6" y1="12" x2="9" y2="12"></line>
                                        <line x1="6" y1="15" x2="8" y2="15"></line>
                                      </svg>
                                      <span class="mx-2">إصدار تقرير جديد</span>
                                    </button>
                                  </DropDown>
                                </td>
                                <td class="text-end">
                                  <div class="btn-list flex-nowrap">
                                    <button
                                      v-if="role == 1"
                                      @click="
                                        $router.push({
                                          name: 'moderations_object',
                                          params: {
                                            content_type_id: 7,
                                            object_id: report.id,
                                          },
                                        })
                                      "
                                      class="btn btn-icon"
                                    >
                                      <!-- Download SVG icon from http://tabler-icons.io/i/history -->
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <polyline points="12 8 12 12 14 14" />
                                        <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
                                      </svg>
                                    </button>

                                    <button
                                      class="btn btn-icon"
                                      @click="
                                        $router.push({
                                          name: 'edit_report',
                                          params: { id: report.report_uid },
                                        })
                                      "
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-edit"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path
                                          d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                        ></path>
                                        <path
                                          d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                        ></path>
                                        <path d="M16 5l3 3"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div v-if="role == 1" class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">سجل العميل</h4>
                  </div>
                  <div class="card-body p-0">
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="client-logs-table table-responsive"
                          ref="scrollContainer"
                        >
                          <table
                            class="table table-vcenter text-nowrap table-striped table-hover"
                          >
                            <thead>
                              <tr>
                                <th class="text-right">المستخدم</th>
                                <th class="text-right">الوصف</th>
                                <th>الوقت</th>
                              </tr>
                            </thead>
                            <infinite-scroll
                              api-endpoint="/api/client-logs/"
                              :id="clientID"
                              :container="$refs.scrollContainer"
                            >
                              <template v-slot="{ item }">
                                <tr>
                                  <td class="text-right">
                                    {{ item.actor }}
                                  </td>
                                  <td class="text-right">
                                    {{ item.verb }} <br />
                                    <template v-if="item.action_object">
                                      {{ item.action_object }} <br />
                                    </template>
                                    - {{ item.timesince }}
                                  </td>

                                  <td class="text-right">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="icon icon-tabler icon-tabler-calendar-event text-muted"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      stroke-width="2"
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <rect
                                        x="4"
                                        y="5"
                                        width="16"
                                        height="16"
                                        rx="2"
                                      ></rect>
                                      <line x1="16" y1="3" x2="16" y2="7"></line>
                                      <line x1="8" y1="3" x2="8" y2="7"></line>
                                      <line x1="4" y1="11" x2="20" y2="11"></line>
                                      <rect x="8" y="15" width="2" height="2"></rect>
                                    </svg>
                                    {{ formate_datetime(item.timestamp) }}
                                  </td>
                                </tr>
                              </template>
                            </infinite-scroll>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterBar />
      <!-- footer -->
    </div>
  </div>

  <!-- Use the existing ModalWindow component -->

  <ModalWindow :visible="showModal" @close="showModal = false">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">حظر العميل</h5>
        <button
          @click="showModal = false"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="noteText" class="form-label">ملاحظة</label>
          <textarea v-model="note" class="form-control" id="noteText" rows="3"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary ms-auto" @click="confirmBlock">تأكيد</button>
        <button class="btn" @click="showModal = false">إلغاء</button>
      </div>
    </div>
  </ModalWindow>
</template>

<script>
import { ref, onMounted, getCurrentInstance, computed } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";
import DropDown from "@/components/DropDown.vue";
import InternalNote from "@/components/InternalNote.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import InfiniteScroll from "@/components/InfiniteScroll.vue";
import { formate_datetime } from "@/script.js";

export default {
  components: {
    FooterBar,
    SideBar,
    DropDown,
    InfiniteScroll,
    InternalNote,
    ModalWindow,
  },
  setup() {
    // Accessing axios from global properties
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios;

    // Reactive state
    const client = ref({});
    const reports = ref([]);
    const height_blood_samples = ref([]);
    const nationality_choices = ref([]);
    const marital_status_choices = ref([]);

    const showModal = ref(false);
    const selectedClientId = ref(null);
    const note = ref("");
    const msg = ref("");
    const form_errors = ref([]);

    // Computed properties
    const role = computed(() => proxy.$store.getters.getUser.role);

    const clientID = computed(() => proxy.$route.params.id);
    // Methods
    const open_sample_code = (sample) => {
      const url = proxy.$router.resolve({
        name: "examination_label",
        params: { height_blood_sample_uid: sample.blood_test_uid },
      });
      window.open(url.href, "_blank");
    };

    const open_report_issue = (issue) => {
      const url = proxy.$router.resolve({
        name: "report_issue_with_pin",
        params: { pin: issue.random_pin, issue_uid: issue.issue_uid },
      });
      window.open(url.href, "_blank");
    };

    const getData = async () => {
      try {
        const response = await axios.get(`/api/client/${client.value.id}/`);
        let height_blood_samples_response;
        let reports_response;

        if (role.value == "1" || role.value == "5") {
          reports_response = await axios.get(
            `/api/report/?report_client=${client.value?.id}`
          );
        }

        if (role.value == "1" || role.value == "5" || role.value == "4") {
          height_blood_samples_response = await axios.get(
            `/api/sample/?client=${client.value?.id}`
          );
        }

        const options_response = await axios.options("/api/client/");

        if (options_response) {
          nationality_choices.value =
            options_response.data.actions.POST.nationality.choices;
          marital_status_choices.value =
            options_response.data.actions.POST.marital_status.choices;
        }

        if (response) client.value = response.data;
        if (reports_response) reports.value = reports_response.data.results;
        if (height_blood_samples_response)
          height_blood_samples.value = height_blood_samples_response.data.results;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const openModal = (id) => {
      selectedClientId.value = id;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const confirmBlock = async () => {
      if (!note.value) {
        alert("يجب عليك تقديم ملاحظة قبل حظر العميل.");
        return;
      }

      showModal.value = false;

      try {
        const url = `/api/client/${selectedClientId.value}/block/`;
        await axios.post(url, {
          status: 0,
          note: note.value,
        });

        const httpError = proxy.$store.getters.getHttpError;
        if (httpError) {
          msg.value = httpError.message;
          if (httpError.response.data) form_errors.value = httpError.response.data;
        } else {
          client.value.status = 0;
        }
      } catch (error) {
        console.error("Error blocking client:", error);
      }
    };

    const creat_new_issue = async (report) => {
      if (
        !confirm("إذا أصدرت تقرير جديد سيتم إلغاء التقرير السابق لهذا الفحص بدون عودة.")
      )
        return false;

      try {
        const issue_response = await axios.post("/api/report-issue/", {
          report: report.id,
        });

        report.reports_issues.forEach(function (issue) {
          issue.status = 0;
        });

        report.reports_issues.unshift(issue_response.data);
      } catch (error) {
        console.error("Error creating new issue:", error);
      }
    };

    const get_last_date_tests = () => {
      return reports.value[0]?.report_date;
    };

    const get_letter_date = () => {
      return client.value.letter_date;
    };

    const get_nationality = (key) => {
      return nationality_choices.value.find((o) => o.value === key)?.display_name;
    };

    const get_marital_status = (key) => {
      return marital_status_choices.value.find((o) => o.value === key)?.display_name;
    };

    // Lifecycle hook
    onMounted(() => {
      client.value.id = proxy.$route.params.id;
      getData();
    });

    return {
      // Refs
      client,
      reports,
      height_blood_samples,
      nationality_choices,
      marital_status_choices,
      showModal,
      selectedClientId,
      note,
      msg,
      form_errors,

      // Computed
      role,
      clientID,

      // Methods
      open_sample_code,
      open_report_issue,
      getData,
      openModal,
      closeModal,
      confirmBlock,
      creat_new_issue,
      get_last_date_tests,
      get_letter_date,
      get_nationality,
      get_marital_status,
      formate_datetime,
    };
  },
};
</script>

<style scoped>
.client-logs-table {
  max-height: 583px;
}
</style>

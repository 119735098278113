<template>
  <SideBar />

  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 v-if="form.id" class="page-title">تعديل عميل</h2>
              <h2 v-else class="page-title">إضافة عميل جديد</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="row row-cards">
            <div class="col-12">
              <form v-on:submit.prevent="submitForm" class="card card-md">
                <div v-if="form_errors" class="invalid-feedback">
                  {{ form_errors.non_field_errors }}
                </div>
                <div class="card-header">
                  <h4 class="card-title">بيانات العميل</h4>
                </div>
                <div class="card-body">
                  <div v-if="form.moderation_status == 0" class="row">
                    <div class="col-12">
                      <div class="alert alert-info alert-dismissible" role="alert">
                        <div class="d-flex">
                          <div>
                            <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                            <!-- SVG icon code with class="alert-icon" -->
                          </div>
                          <div>
                            <h4 class="alert-title">تحت الموافقة</h4>
                            <div class="text-muted">
                              هذا العنصر لازال يتطلب موافقة المدير على التعديلات
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="row">
                        <div class="col-12">
                          <label class="form-label">بيانات المكتب </label>

                          <fieldset
                            class="office-details form-fieldset col-12 mb-4"
                            ref="officeDetails"
                          >
                            <div class="row divide-y">
                              <div class="col-12 mb-3">
                                <label class="form-label">رقم الطلب</label>
                                <input
                                  autocomplete="off"
                                  name="application_no"
                                  class="form-control"
                                  :class="form_errors.application_no ? 'is-invalid' : ''"
                                  v-model="form.application_no"
                                  placeholder="رقم الطلب"
                                />
                                <div class="invalid-feedback">
                                  {{ form_errors.application_no?.[0] }}
                                </div>
                              </div>
                              <div class="col-12 mb-3">
                                <label class="form-label">رقم الخطاب</label>
                                <input
                                  autocomplete="off"
                                  name="letter_no"
                                  class="form-control"
                                  :class="form_errors.letter_no ? 'is-invalid' : ''"
                                  v-model="form.letter_no"
                                  placeholder="رقم الخطاب"
                                />
                                <div class="invalid-feedback">
                                  {{ form_errors.letter_no?.[0] }}
                                </div>
                              </div>
                              <div class="col-12 mb-3">
                                <label class="form-label">تاريخ الخطاب</label>
                                <input
                                  autocomplete="off"
                                  name="letter_date"
                                  type="date"
                                  class="form-control"
                                  :class="form_errors.letter_date ? 'is-invalid' : ''"
                                  v-model="form.letter_date"
                                />
                                <div class="invalid-feedback">
                                  {{ form_errors.letter_date?.[0] }}
                                </div>
                              </div>
                              <!-- Use the vue-select component -->
                              <div class="col-12 mb-3">
                                <label class="form-label">اسم المكتب</label>
                                <v-select
                                  class="form-control"
                                  :class="form_errors.office ? 'is-invalid' : ''"
                                  v-model="selected_office"
                                  :options="offices"
                                  :searchable="true"
                                  label="name"
                                  placeholder="ابحث عن المكتب"
                                  @search="searchOffices"
                                  :loading="loading"
                                  @update:modelValue="onOfficeSelect"
                                />
                                <div class="invalid-feedback">
                                  {{ form_errors.office?.[0] }}
                                </div>
                              </div>

                              <!-- Use the vue-select component -->
                              <div class="col-12 mb-3">
                                <label class="form-label">اسم المندوب</label>
                                <v-select
                                  class="form-control"
                                  :class="form_errors.agent ? 'is-invalid' : ''"
                                  v-model="selected_agent"
                                  :options="agents"
                                  :searchable="true"
                                  label="name"
                                  placeholder="ابحث عن  مندوب"
                                  @search="searchAgents"
                                  :loading="loading"
                                  @update:modelValue="onAgentSelect"
                                />
                                <div class="invalid-feedback">
                                  {{ form_errors.agent?.[0] }}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <label class="form-label">ملاحظات</label>

                          <fieldset class="form-fieldset col-12 mb-4">
                            <div class="row divide-y">
                              <div class="col-12 mb-3">
                                <textarea
                                  class="form-control"
                                  :class="form_errors.notes ? 'is-invalid' : ''"
                                  v-model="form.notes"
                                />

                                <div class="invalid-feedback">
                                  {{ form_errors.notes?.[0] }}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-lg-6">
                      <label class="form-label">البيانات الأساسية</label>
                      <fieldset class="form-fieldset col-12 mb-4">
                        <div class="row divide-y">
                          <div class="col-12 mb-3">
                            <label class="form-label">الاسم</label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.name ? 'is-invalid' : ''"
                              v-model="form.name"
                              placeholder="الأسم"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.name?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 mb-3">
                            <label class="form-label">تاريخ الميلاد</label>
                            <input
                              autocomplete="off"
                              type="date"
                              class="form-control"
                              :class="form_errors.dob ? 'is-invalid' : ''"
                              v-model="form.dob"
                            />
                            <div class="invalid-feedback">{{ form_errors.dob?.[0] }}</div>
                          </div>
                          <div class="col-6 mb-3">
                            <label class="form-label">الحالة الاجتماعية</label>
                            <select
                              class="form-control"
                              :class="form_errors.marital_status ? 'is-invalid' : ''"
                              v-model="form.marital_status"
                              placeholder="الحالةالإجتماعية .."
                            >
                              <option value="" disabled hidden>
                                الحالة الإجتماعية ..
                              </option>
                              <option
                                v-for="choice in marital_status_choices"
                                :value="choice.value"
                                :key="choice.value"
                              >
                                {{ choice.display_name }}
                              </option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.marital_status?.[0] }}
                            </div>
                          </div>
                          <div class="col-6 mb-3">
                            <label class="form-label">الجنسية</label>
                            <select
                              class="form-control"
                              :class="form_errors.nationality ? 'is-invalid' : ''"
                              v-model="form.nationality"
                            >
                              <option value="" disabled hidden>الجنسية ..</option>
                              <option
                                v-for="choice in nationality_choices"
                                :value="choice.value"
                                :key="choice.value"
                              >
                                {{ choice.display_name }}
                              </option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.nationality?.[0] }}
                            </div>
                          </div>
                          <div class="col-6 mb-3">
                            <label class="form-label">المهنة</label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.occupation ? 'is-invalid' : ''"
                              v-model="form.occupation"
                              placeholder="المهنة"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.occupation?.[0] }}
                            </div>
                          </div>
                          <div class="col-6 mb-3">
                            <label class="form-label">رقم الهاتف</label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.mobile ? 'is-invalid' : ''"
                              v-model="form.mobile"
                              placeholder="رقم الهاتف"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.mobile?.[0] }}
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-label">الصورة الشخصية للعميل</div>

                            <div>
                              <input
                                autocomplete="off"
                                ref="file"
                                type="file"
                                class="form-control"
                                :class="form_errors.photo ? 'is-invalid' : ''"
                                @change="editImage"
                                accept="image/*"
                              />
                              <div class="invalid-feedback">
                                {{ form_errors.photo?.[0] }}
                              </div>
                              <a
                                class="input-group-text"
                                v-if="form.photo && !form.photo?.name"
                                :href="form.photo"
                                target="_blank"
                              >
                                <span
                                  class="avatar avatar-md ms-2"
                                  :style="{ backgroundImage: `url(${form.photo})` }"
                                ></span>
                              </a>

                              <a
                                class="input-group-text"
                                v-else-if="imageTempUrl"
                                :href="imageTempUrl"
                                target="_blank"
                              >
                                <span
                                  class="avatar avatar-md ms-2"
                                  :style="{ backgroundImage: `url(${imageTempUrl})` }"
                                ></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <label class="form-label">جواز العميل</label>
                      <fieldset class="form-fieldset col-12 mb-4">
                        <div class="row">
                          <div class="col-12 col-lg-6 mb-3">
                            <label class="form-label">رقم الجواز</label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.passport_no ? 'is-invalid' : ''"
                              v-model="form.passport_no"
                              placeholder="رقم الجواز"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.passport_no?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-3">
                            <label class="form-label">مكان الإصدار</label>
                            <select
                              class="form-control"
                              :class="form_errors.place_of_issue ? 'is-invalid' : ''"
                              v-model="form.place_of_issue"
                            >
                              <option value="" disabled hidden>مكان الإصدار ..</option>
                              <option
                                v-for="(option, index) in placeOfIssueOptions"
                                :key="index"
                                :value="option.value"
                              >
                                {{ option.label }}
                              </option>
                              <option value="other">أضف مكان اخر</option>
                            </select>
                            <input
                              autocomplete="off"
                              v-if="
                                form.place_of_issue === 'other' ||
                                form.place_of_issue === customPlace
                              "
                              class="form-control"
                              v-model="customPlace"
                              :class="form_errors.place_of_issue ? 'is-invalid' : ''"
                              placeholder="ادخل مكان الاصدار ..."
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.place_of_issue?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 mb-3">
                            <label class="form-label">تاريخ الإصدار</label>
                            <input
                              autocomplete="off"
                              type="date"
                              class="form-control"
                              :class="form_errors.date_of_issue ? 'is-invalid' : ''"
                              v-model="form.date_of_issue"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.date_of_issue?.[0] }}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div class="card-footer text-end">
                  <div class="d-flex order-reversed">
                    <a href="#" onclick="history.back()" class="btn">إلغاء</a>
                    <button
                      :disabled="!isFormChanged"
                      type="submit"
                      class="btn btn-primary ms-auto"
                    >
                      حفظ
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";
import vSelect from "vue3-select";
import "vue3-select/dist/vue3-select.css";
import * as utilis from "@/script.js";

export default {
  components: {
    FooterBar,
    SideBar,
    vSelect,
  },
  setup() {
    // Access axios and other global properties using getCurrentInstance
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // Accessing axios from global properties

    // Reactive state
    const customPlace = ref("");
    const placeOfIssueOptions = ref([]);
    const form = ref({
      name: "",
      dob: "",
      passport_no: "",
      date_of_issue: "",
      place_of_issue: "عدن",
      nationality: "YE",
      marital_status: 0,
      occupation: "",
      mobile: "",
      photo: "",
      letter_date: "",
      application_no: "",
      letter_no: "",
      office: "",
      agent: 1, // Default to the main office
      notes: "",
    });
    const nationality_choices = ref([]);
    const marital_status_choices = ref([]);
    const msg = ref("");
    const officeSearchQuery = ref("");
    const selected_office = ref("");
    const agentSearchQuery = ref("");
    const selected_agent = ref({
      id: 1,
      name: "المركز الرئيسي", // Default to the main office
    });
    const offices = ref([]);
    const agents = ref([]);
    const form_errors = ref([]);
    const initialForm = ref(null);
    const loading = ref(false);

    // Reactive reference to store the image URL
    const imageTempUrl = ref(null);

    // Computed property
    const isFormChanged = computed(() => {
      return JSON.stringify(form.value) !== JSON.stringify(initialForm.value);
    });

    // Methods
    const onOfficeSelect = (selectedValue) => {
      form.value.office = selectedValue?.id || null;
    };

    const onAgentSelect = (selectedValue) => {
      form.value.agent = selectedValue?.id || null;
    };

    const searchOffices = async (officeSearchQuery) => {
      loading.value = true;

      if (officeSearchQuery.length > 2) {
        try {
          const response = await axios.get("/api/office/active/", {
            params: { search: officeSearchQuery },
          });
          offices.value = response.data.results;
        } catch (error) {
          console.error("Error fetching offices:", error);
        }
      } else {
        offices.value = [];
      }

      loading.value = false;
    };

    const searchAgents = async (agentSearchQuery) => {
      loading.value = true;

      if (agentSearchQuery.length > 2) {
        try {
          const response = await axios.get("/api/agent/active/", {
            params: { search: agentSearchQuery },
          });
          agents.value = response.data.results;
        } catch (error) {
          console.error("Error fetching agents:", error);
        }
      } else {
        agents.value = [];
      }

      loading.value = false;
    };

    const set_custom_place_of_issue_value = () => {
      const valueExists = placeOfIssueOptions.value.some(
        (option) => option.value === form.value.place_of_issue
      );
      if (!valueExists) {
        placeOfIssueOptions.value.push({
          value: form.value.place_of_issue,
          label: form.value.place_of_issue,
        });
      }

      customPlace.value = "";
    };

    const office_details_is_valid = () => {
      return true; // Implement your validation logic here if needed
    };

    const editImage = (event) => {
      const fileInput = event.target.files[0];
      if (fileInput) {
        form.value.photo = fileInput; // Save the file object
        imageTempUrl.value = URL.createObjectURL(fileInput); // Save the image URL
      } else {
        form.value.photo = "";
      }
    };
    const submitForm = async () => {
      if (!office_details_is_valid()) return;

      const headers = { "Content-Type": "multipart/form-data" };
      form.value.date_of_issue = utilis.removeTime(form.value.date_of_issue);
      form.value.dob = utilis.removeTime(form.value.dob);
      form.value.letter_date = utilis.removeTime(form.value.letter_date);

      if (form.value.place_of_issue === "other") {
        if (!customPlace.value.trim()) {
          form_errors.value.place_of_issue = ["يرجى إدخال المكان"];
          return;
        }
        form.value.place_of_issue = customPlace.value;
      }

      let post_method = axios.post;
      let url = `/api/client/`;
      if (form.value.id) {
        post_method = axios.put;
        url = `/api/client/${form.value.id}/`;
      }
      if (!form.value.photo?.name) delete form.value.photo;

      await post_method(url, form.value, { headers }).then(() => {
        if (proxy.$store.getters.getHttpError) {
          set_custom_place_of_issue_value();
          let error_obj = proxy.$store.getters.getHttpError;
          msg.value = error_obj.message;
          if (error_obj.response.data) form_errors.value = error_obj.response.data;
        } else {
          proxy.$router.replace({ name: "client" });
        }
      });
    };

    const getData = async () => {
      const options_response = await axios.get(`/api/place-of-issue-choices/`);
      placeOfIssueOptions.value = options_response.data;

      const response = await axios.options("/api/client/");
      if (response) {
        nationality_choices.value = response.data.actions.POST.nationality.choices;
        marital_status_choices.value = response.data.actions.POST.marital_status.choices;
      }

      if (form.value.id) {
        const response = await axios.get(`/api/client/${form.value.id}/`);
        if (response) {
          form.value = response.data;
          selected_office.value = response.data.office_name;
          selected_agent.value = response.data.agent_name;
          set_custom_place_of_issue_value();
        }
      }

      if (proxy.$store.getters.getHttpError)
        msg.value = proxy.$store.getters.getHttpError.message;
    };

    // Lifecycle hook
    onMounted(async () => {
      form.value.id = proxy.$route.params.id;
      await getData();
      initialForm.value = { ...form.value };
    });

    // Return the reactive properties, computed, and methods to the template
    return {
      customPlace,
      placeOfIssueOptions,
      form,
      nationality_choices,
      marital_status_choices,
      msg,
      officeSearchQuery,
      selected_office,
      agentSearchQuery,
      selected_agent,
      offices,
      agents,
      form_errors,
      initialForm,
      loading,
      isFormChanged,
      onOfficeSelect,
      onAgentSelect,
      searchOffices,
      searchAgents,
      set_custom_place_of_issue_value,
      office_details_is_valid,
      submitForm,
      getData,
      editImage,
      imageTempUrl,
    };
  },
};
</script>

import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import ClientView from '@/views/client/ClientView.vue';
import ClientAddView from '@/views/client/ClientAddView.vue';
import ClientDetailView from '@/views/client/ClientDetailView.vue';


import AgentView from '@/views/agent/AgentView.vue';
import AgentAddView from '@/views/agent/AgentAddView.vue';

import ClientStatisticsView from '@/views/admin/ClientStatisticsView.vue';

import OfficeView from '@/views/office/OfficeView.vue';
import OfficeAddView from '@/views/office/OfficeAddView.vue';


import ClientExaminationLabelView from '@/views/client/ClientExaminationLabelView.vue';


import ClientHeightView from '@/views/sample/ClientHeightView.vue';

import SampleView from '@/views/sample/SampleView.vue';


import ReportView from '@/views/report/ReportView.vue';
import ReportIssueView from '@/views/report/ReportIssueView.vue';
import ReportIssueAuthView from '@/views/report/ReportIssueAuthView.vue';
import ReportAddView from '@/views/report/ReportAddView.vue';
import LogsView from '@/views/admin/LogsView.vue';
import ModerationsView from '@/views/admin/ModerationsView.vue';
import UserAddView from '@/views/admin/UserAddView.vue';
import UserView from '@/views/admin/UserView.vue';
import LoginOTPView from '@/views/LoginOTPView.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import store from '@/store';
import ClientBlockView from '@/views/admin/ClientBlockView.vue';

const routes = [
  {
    path: '/admin/users/:id/',
    name: 'edit_user',
    component: UserAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | تعديل مستخدم',
    }
  },
  {
    path: '/admin/users/add/',
    name: 'add_user',
    component: UserAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | اضافة مستخدم',

    }
  },

  {
    path: '/admin/users/',
    name: 'users',
    component: UserView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | المستخدمين ',

    }
  },

  {
    path: '/admin/client-statistics/',
    name: 'client_statistics',
    component: ClientStatisticsView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر |  احصائيات العملاء ',

    }
  },

  {
    path: '/admin/logs/',
    name: 'logs',
    component: LogsView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر |  السجلات',

    }
  },
  {
    path: '/admin/moderations/:content_type_id/:object_id/',
    name: 'moderations_object',
    component: ModerationsView,
    meta: {
      requiresAuth: true,
      title: ' عمستشفى صابر | عمليات التعديل على العنصر ',

    }
  },


  {
    path: '/admin/moderations/',
    name: 'moderations',
    component: ModerationsView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | عمليات التعديل ',

    }
  },


  {
    path: '/admin/block-list/',
    name: 'block_list',
    component: ClientBlockView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر |  قائمة الحظر ',

    }
  },
  {
    path: '/reports/:uid/',
    name: 'report_issue_issue_uid',
    component: ReportIssueAuthView,
    meta: {
      title: 'مستشفى صابر | تقرير ',

    }
  },
  {
    path: '/reports/',
    name: 'report_issue_issue',
    component: ReportIssueAuthView,
    meta: {
      title: 'مستشفى صابر | التقاير ',

    }
  },

  {
    path: '/login/',
    name: 'login',
    component: LoginOTPView,
    meta: {
      title: 'مستشفى صابر | تسجيل الدخول ',

    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | الشهادات الصحية ',

    }
  },

  {
    path: '/office/',
    name: 'Office',
    component: OfficeView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | المكاتب ',

    }
  },
  {
    path: '/office/add/',
    name: 'add_office',
    component: OfficeAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | اضافة مكتب ',

    }
  },
  {
    path: '/office/:id/',
    name: 'edit_office',
    component: OfficeAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | تعديل مكتب ',

    }
  },

  {
    path: '/agent/',
    name: 'Agent',
    component: AgentView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | المندوبين ',

    }
  },
  {
    path: '/agent/add/',
    name: 'add_agent',
    component: AgentAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | اضافة مندوب ',

    }
  },
  {
    path: '/agent/:id/',
    name: 'edit_agent',
    component: AgentAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | تعديل مندوب ',

    }
  },

  {
    path: '/client/',
    name: 'client',
    component: ClientView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | العملاء ',

    }
  },
  {
    path: '/client/add/',
    name: 'add_client',
    component: ClientAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | اضافة عميل ',

    }
  },
  {
    path: '/client/detail/:id/',
    name: 'detail_client',
    component: ClientDetailView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | بيانات العميل ',

    }
  },
  {
    path: '/client/:id/',
    name: 'edit_client',
    component: ClientAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | تعديل عميل ',

    }
  },
  {
    path: '/client/height/:id/',
    name: 'height_client',
    component: ClientHeightView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | اضافة طول عميل ',

    }
  },
  {
    path: '/client/height/:id/:blood_test_uid',
    name: 'edit_height_client',
    component: ClientHeightView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | تعديل طول العميل',
    }
  },

  {
    path: '/client/detail/:height_blood_sample_uid/examination-label/',
    name: 'examination_label',
    component: ClientExaminationLabelView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | ملصق الفحص ',

    }
  },

  {
    path: '/report/add/',
    name: 'add_report',
    component: ReportAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | اضافة فحص ',

    }
  },

  {
    path: '/report/issue/:issue_uid/:pin/',
    name: 'report_issue_with_pin',
    component: ReportIssueView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | تقرير ',

    }

  },
  {
    path: '/report/client/:height_blood_sample_uid/add/',
    name: 'add_report_for_blood_sample',
    component: ReportAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | اضافة فحص ',

    }
  },
  {
    path: '/report/',
    name: 'report',
    component: ReportView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | الفحوصات ',

    }
  },
  {
    path: '/report/:id/',
    name: 'edit_report',
    component: ReportAddView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | تعديل الفحص ',

    }
  },

  {
    path: '/sample/',
    name: 'sample',
    component: SampleView,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | العينات ',

    }
  },

  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
    meta: {
      requiresAuth: true,
      title: 'مستشفى صابر | صفحة غير موجودة 404',

    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  scrollBehavior() {
    // Always scroll to top
    return { top: 0 }
  },
  routes
})





// ... 

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // Check for authentication
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isLoggedIn) {
    next({ name: 'login' });
    return;
  }

  // Find the nearest route with a title
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route with meta tags
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // Set the document (page) title
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none
  if (!nearestWithMeta) {
    next();
    return;
  }

  // Add new meta tags to the document head
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // Track which meta tags we create so we don't interfere with others
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
});


// ...
export default router
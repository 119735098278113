<template>
    <SideBar />
    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                قائمة المندوبين
                            </h2>
                        </div>
                        <!-- Page title actions -->
                        <div class="col-auto ms-auto d-print-none">
                            <div class="btn-list">
                                <router-link to="/agent/add/" class="btn btn-primary  d-sm-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    إضافة مندوب جديد
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">

                    <div class="col-12">
                        <div class="card">
                            <SearchBar :dataUrl=dataUrl />
                            <!-- search -->
                            <div v-if="agents.length" class="table-responsive">
                                <table class="table card-table table-vcenter text-nowrap  table-hover">
                                    <thead>
                                        <tr>
                                            <th class="w-1">#</th>
                                            <th>اسم المندوب
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-sm text-dark icon-thick" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="6 15 12 9 18 15" />
                                                </svg>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="agent in agents" :key="agent.id"
                                            :class="{ 'bg-red-lt': agent.status == 0 }">
                                            <td><span class="text-muted">{{ agent.id }}</span></td>
                                            <td>
                                                {{ agent.name }}
                                            </td>


                                            <td class="text-end">
                                                <div class="btn-list flex-nowrap">

                                                    <button
                                                        @click="$router.push({ name: 'edit_agent', params: { id: agent.id } })"
                                                        class="btn d-sm-inline-block">
                                                        <span class="mx-1">تعديل</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="icon icon-tabler icon-tabler-edit" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path
                                                                d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1">
                                                            </path>
                                                            <path
                                                                d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z">
                                                            </path>
                                                            <path d="M16 5l3 3"></path>
                                                        </svg>
                                                    </button>
                                                    <button v-if="agent.status == 1"
                                                        @click="change_status(agent.id, agent.status)"
                                                        class="btn btn-warning d-sm-inline-block">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <line x1="4" y1="7" x2="20" y2="7" />
                                                            <line x1="10" y1="11" x2="10" y2="17" />
                                                            <line x1="14" y1="11" x2="14" y2="17" />
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                        <span class="mx-1">تعطيل</span>
                                                    </button>
                                                    <button v-if="agent.status == 0"
                                                        @click="change_status(agent.id, agent.status)"
                                                        class="btn btn-info d-sm-inline-block">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <line x1="4" y1="7" x2="20" y2="7" />
                                                            <line x1="10" y1="11" x2="10" y2="17" />
                                                            <line x1="14" y1="11" x2="14" y2="17" />
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                        <span class="mx-1">تفعيل</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- pagination -->
                            <PaginationBar :pagination_data=pagination_data />
                        </div>
                    </div>

                </div>
            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>



<script>
import { ref, onMounted, getCurrentInstance, watch } from 'vue';
import PaginationBar from '@/components/PaginationBar.vue';
import SearchBar from '@/components/SearchBar.vue';
import SideBar from '@/components/SideBar.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
    components: {
        PaginationBar,
        SearchBar,
        SideBar,
        FooterBar
    },
    setup() {
        // Accessing axios from global properties
        const { proxy } = getCurrentInstance();
        const axios = proxy.axios; // Now you can use axios

        // Reactive state
        const agents = ref([]);
        const msg = ref('');
        const dataUrl = '/api/agent/';
        const pagination_data = ref({});
        const urlArgs = ref('');
        const form_errors = ref([]);

        // Methods
        const change_status = async (id, status) => {
            if (confirm("تغيير حالة المندوب؟") !== true) return false;

            const url = dataUrl + id + '/change_status/';

            try {
                await axios.post(url, { status: !status });

                const index = agents.value.findIndex(item => item.id === id);
                if (index !== -1) {
                    agents.value[index].status = !status;
                }

                if (proxy.$store.getters.getHttpError) {
                    let error_obj = proxy.$store.getters.getHttpError;
                    msg.value = error_obj.message;
                    if (error_obj.response.data) form_errors.value = error_obj.response.data;
                }

            } catch (error) {
                console.error(error);
            }
        };

        const getData = async (url) => {
            try {
                const response = await axios.get(url);
                if (response) {
                    agents.value = response.data.results;
                    pagination_data.value = response.data;
                    const urlParams = new URL(response.request.responseURL);
                    urlArgs.value = urlParams.search;

                    proxy.$router.replace(urlArgs.value, { silent: true });
                }

                if (proxy.$store.getters.getHttpError) {
                    msg.value = proxy.$store.getters.getHttpError.message;
                }

            } catch (error) {
                console.error(error);
            }
        };

        // Lifecycle hook equivalent to 'created'
        onMounted(() => {
            urlArgs.value = window.location.search;
            getData(dataUrl + urlArgs.value);
        });

        // Watch for route changes and refetch data
        watch(
            () => proxy.$route.query,
            (query) => {
                if (Object.keys(query).length === 0) {
                    getData(dataUrl);
                }
            }
        );

        return {
            agents,
            msg,
            pagination_data,
            urlArgs,
            form_errors,
            change_status,
            getData,
            dataUrl
        };
    }
};
</script>

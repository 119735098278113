<template>
    <SideBar />
    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                قائمة الفحوصات
                            </h2>
                        </div>
                        <!-- Page title actions -->
                        <!-- <div class="col-auto ms-auto d-print-none">
                            <div class="btn-list">
                                <router-link to="/report/add/" class="btn btn-primary d-sm-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    إضافة فحص جديد
                                </router-link>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">

                    <div class="col-12">
                        <div class="card">
                            <SearchBar :dataUrl=dataUrl :legends=true />
                            <!-- search -->
                            <div v-if="reports.length" class="table-responsive  overflow-visible">
                                <table
                                    class="table card-table table-vcenter text-nowrap datatable table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th class="w-1">#</th>
                                            <th>كود الفحص
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-sm text-dark icon-thick" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="6 15 12 9 18 15" />
                                                </svg>
                                            </th>
                                            <th class="text-center">كود العينة </th>

                                            <th class="text-center">تاريخ الفحص</th>
                                            <th class="text-center">التقارير الصادرة</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="report in reports" :key="report.id">
                                            <td>
                                                <span class="text-muted">
                                                    <small>
                                                        <span v-if="report.moderation_status == 0"
                                                            class="badge rounded-pill bg-warning">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon"
                                                                width="24" height="24" viewBox="0 0 24 24"
                                                                stroke-width="2" stroke="currentColor" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <line x1="9" y1="12" x2="15" y2="12" />
                                                            </svg>
                                                        </span>
                                                        <span v-else class="badge rounded-pill bg-green">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon"
                                                                width="24" height="24" viewBox="0 0 24 24"
                                                                stroke-width="2" stroke="currentColor" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" />
                                                            </svg>
                                                        </span>
                                                    </small>
                                                </span>
                                            </td>
                                            <td>

                                                {{ report.report_uid }}
                                            </td>

                                            <td class="text-center">{{ report.sample_data.blood_test_uid }}</td>

                                            <td class="text-center">{{ report.report_date }}</td>
                                            <td class="text-center">
                                                <!-- dropdown -->
                                                <DropDown :count=report.reports_issues?.length>
                                                    <h6 class="dropdown-header">قائمة التقارير</h6>
                                                    <a v-for="issue in report.reports_issues"
                                                        @click="open_report_issue(issue)" :key="issue.id"
                                                        class="dropdown-item" href="#">
                                                        <svg v-if="issue.status == 1" xmlns="http://www.w3.org/2000/svg"
                                                            class="icon icon-tabler text-green icon-tabler-printer"
                                                            width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path
                                                                d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2">
                                                            </path>
                                                            <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4">
                                                            </path>
                                                            <rect x="7" y="13" width="10" height="8" rx="2"></rect>
                                                        </svg>
                                                        <svg v-else xmlns="http://www.w3.org/2000/svg"
                                                            class="icon icon-tabler text-red icon-tabler-printer"
                                                            width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path
                                                                d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2">
                                                            </path>
                                                            <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4">
                                                            </path>
                                                            <rect x="7" y="13" width="10" height="8" rx="2"></rect>
                                                        </svg>
                                                        <span class="mx-2">تقرير {{ issue.created }}
                                                        </span>
                                                    </a>

                                                    <div class="dropdown-divider"></div>
                                                    <button @click="creat_new_issue(report)" class="dropdown-item">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="icon icon-tabler icon-tabler-certificate" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx="15" cy="15" r="3"></circle>
                                                            <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5">
                                                            </path>
                                                            <path
                                                                d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73">
                                                            </path>
                                                            <line x1="6" y1="9" x2="18" y2="9"></line>
                                                            <line x1="6" y1="12" x2="9" y2="12"></line>
                                                            <line x1="6" y1="15" x2="8" y2="15"></line>
                                                        </svg>
                                                        <span class="mx-2">إصدار تقرير جديد</span>
                                                    </button>
                                                </DropDown>
                                            </td>
                                            <td class="text-end">
                                                <button
                                                    @click="$router.push({ name: 'edit_report', params: { id: report.report_uid } })"
                                                    class="btn d-sm-inline-block">
                                                    <span class="mx-1">تعديل</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-edit" width="24" height="24"
                                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path
                                                            d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1">
                                                        </path>
                                                        <path
                                                            d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z">
                                                        </path>
                                                        <path d="M16 5l3 3"></path>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- pagination -->
                            <PaginationBar :pagination_data=pagination_data />
                        </div>
                    </div>

                </div>
            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>

</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue';
import PaginationBar from '@/components/PaginationBar.vue';
import SearchBar from '@/components/SearchBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import SideBar from '@/components/SideBar.vue';
import DropDown from '@/components/DropDown.vue';

export default {
    components: {
        PaginationBar,
        SearchBar,
        FooterBar,
        SideBar,
        DropDown
    },
    setup() {
        // Accessing axios and other global properties
        const { proxy } = getCurrentInstance();
        const axios = proxy.axios;  // Access axios from global properties

        // Reactive state
        const reports = ref([]);
        const msg = ref('');
        const dataUrl = '/api/report/';
        const pagination_data = ref('');
        const urlArgs = ref('');

        // Methods
        const open_report_issue = (issue) => {
            let url = proxy.$router.resolve({ name: 'report_issue_with_pin', params: { pin: issue.random_pin, issue_uid: issue.issue_uid } });
            window.open(url.href, '_blank');
        };

        const getData = async (url) => {
            try {
                const response = await axios.get(url);
                if (response) {
                    reports.value = response.data.results;
                    pagination_data.value = response.data;
                    const urlParams = new URL(response.request.responseURL);
                    urlArgs.value = urlParams.search;
                }
            } catch (error) {
                console.error(error);
            }
            if (proxy.$store.getters.getHttpError) {
                msg.value = proxy.$store.getters.getHttpError.message;
            }
        };

        const creat_new_issue = async (report) => {
            if (confirm("إذا أصدرت تقرير جديد سيتم إلغاء التقرير السابق لهذا الفحص بدون عودة.") !== true) return false;
            try {
                const issue_response = await axios.post('/api/report-issue/', { 'report': report.id });
                // Perform Success Action
                report.reports_issues.forEach(function (issue) {
                    issue.status = 0;
                });
                report.reports_issues.unshift(issue_response.data);
            } catch (error) {
                console.error(error);
            }
        };

        // Lifecycle hook equivalent to 'created'
        onMounted(() => {
            urlArgs.value = window.location.search;
            getData(dataUrl + urlArgs.value);
        });

        return {
            reports,
            msg,
            pagination_data,
            urlArgs,
            open_report_issue,
            getData,
            creat_new_issue,
            dataUrl
        };
    }
};
</script>

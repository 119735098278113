<template>
  <div v-if="isLoading" class="loading-indicator">
    <span class="loader"></span>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';

export default {
  setup() {
    // Access the global store
    const { proxy } = getCurrentInstance();
    const isLoading = computed(() => proxy.$store.getters.isLoading);

    return {
      isLoading
    };
  }
};
</script>

<style>
.loading-indicator {
  /* Center the loading indicator */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Optional styles to overlay on top of other content */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  /* Semi-transparent background */
}

.loading-indicator img {
  /* Optional styles for the loading GIF */
  width: 100px;
  /* Adjust the width as needed */
}

/* the loader */
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #999;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}
</style>

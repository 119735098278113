<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-content">
      <slot></slot>
    </div>
  </div>


</template>

<script>

export default ({
  name: 'ModalWindow',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    // Method to close the modal
    const closeModal = () => {
      emit('close');
    };

    return {
      closeModal
    };
  }
});
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
}
</style>
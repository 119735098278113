<template>
  <div class="row align-items-center">
    <div class="col-auto">
      <span
        class="badge"
        :class="internalnote.status === 1 ? 'bg-red' : 'bg-blue'"
      ></span>
    </div>

    <div class="col">
      {{ internalnote.note }}
      <div class="text-secondary btn-list flex">
        <div>{{ internalnote.created_by }} -</div>
        <div>{{ formate_datetime(internalnote.created) }}</div>
      </div>
    </div>
    <div class="col-auto">
      <div v-click-away="onClickAway" class="dropdown">
        <a
          @click="toggle"
          class="btn-action"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <!-- Download SVG icon from http://tabler-icons.io/i/dots-vertical -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
            <path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
            <path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
          </svg>
        </a>
        <div
          :class="activeDropDown ? 'show' : ''"
          class="dropdown-menu dropdown-menu-end"
          style=""
        >
          <button
            v-if="internalnote.status == 1"
            @click="change_status"
            class="dropdown-item text-primary"
          >
            إنهاء الملاحظة
          </button>
          <button v-else @click="change_status" class="dropdown-item text-warning">
            تنشيط الملاحظة
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, getCurrentInstance } from "vue";
import { formate_datetime } from "@/script.js";

export default {
  props: ["internalnote"],
  setup(props, { emit }) {
    // Accessing axios and other global properties
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // Access axios from global properties

    // Reactive state
    const activeDropDown = ref(false);
    const msg = ref("");
    const form_errors = ref([]);

    // Methods
    const change_status = async () => {
      const note_status = props.internalnote.status === 1 ? 0 : 1;
      const url = `/api/internal-note/${props.internalnote.id}/toggle_note_status/`;

      try {
        await axios.post(url, { status: note_status });
        if (proxy.$store.getters.getHttpError) {
          const error_obj = proxy.$store.getters.getHttpError;
          msg.value = error_obj.message;
          if (error_obj.response.data) {
            form_errors.value = error_obj.response.data;
          }
        }
        // Emit event to parent component
        emit("status-updated");
      } catch (error) {
        console.error("Error changing status:", error);
      }
    };

    const toggle = () => {
      activeDropDown.value = !activeDropDown.value;
    };

    const onClickAway = () => {
      if (activeDropDown.value) {
        activeDropDown.value = false;
      }
    };

    return {
      formate_datetime,
      activeDropDown,
      msg,
      form_errors,
      change_status,
      toggle,
      onClickAway,
    };
  },
};
</script>

<style scoped>
.dropdown-item,
.dropdown-menu {
  min-width: auto;
}
</style>

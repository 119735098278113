<template>
    <div v-if="pagination_data.count" class="card-footer d-flex align-items-center">
        <p class="m-0 text-muted">عرض من <span>{{ indexDataFrom }}</span> إلى <span>{{ indexDataTo }}</span> من مجموع
            <span>{{
                pagination_data.count
            }}</span>
        </p>
        <ul class="pagination m-0 ms-auto">
            <li class="page-item" :class="previousUrl ? '' : 'disabled'">
                <a class="page-link" href="#" @click.prevent="loadPrev()" tabindex="-1"
                    :aria-disabled="previousUrl ? 'false' : 'true'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="9 6 15 12 9 18" />
                    </svg>
                    السابق
                </a>
            </li>

            <li class="page-item" :class="nextUrl ? '' : 'disabled'">
                <a class="page-link" href="#" @click.prevent="loadNext()"
                    :aria-disabled="nextUrl ? 'false' : 'true'">التالي
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="15 6 9 12 15 18" />
                    </svg>
                </a>
            </li>
        </ul>
    </div>
    <div v-else class="card-footer d-flex align-items-center">
        <p class="m-0 text-muted">لا يوجد بيانات</p>
    </div>
</template>

<script>
import { ref, watch, getCurrentInstance } from 'vue';

export default {
    props: ['pagination_data', 'urlArgs'],

    setup(props) {
        const { proxy } = getCurrentInstance();
        const router = proxy.$router;
        const route = proxy.$route;

        // Reactive state variables
        const nextUrl = ref('');
        const previousUrl = ref('');
        const indexDataFrom = ref('');
        const indexDataTo = ref('');
        const results_count = ref('');

        // Helper function to extract query string from URL
        const get_queryString = (url) => {
            const url_obj = new URL(url);
            return url_obj.search;
        };

        // Load next page logic
        const loadNext = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            const queryString = get_queryString(nextUrl.value);
            router.push(queryString, { silent: true });
            getIndexingDetails(nextUrl.value);
        };

        // Load previous page logic
        const loadPrev = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            const queryString = get_queryString(previousUrl.value);
            router.push(queryString, { silent: true });
            getIndexingDetails(previousUrl.value);
        };

        // Fetch indexing data logic
        const getIndexingDetails = (url) => {
            if (url) {
                const offset = route.query.offset;
                const limit = route.query.limit;
                indexDataFrom.value = offset;
                indexDataTo.value = limit;
            }
        };

        // Watch for changes in pagination data
        watch(
            () => props.pagination_data,
            (newPaginationData) => {
                results_count.value = newPaginationData.results?.length;

                const offset = parseInt(route.query.offset || 0) + 1;
                const limit = parseInt(route.query.limit || results_count.value);

                indexDataFrom.value = offset;
                indexDataTo.value = offset + limit - 1 > newPaginationData.count
                    ? newPaginationData.count
                    : offset + limit - 1;

                nextUrl.value = newPaginationData.next;
                previousUrl.value = newPaginationData.previous;
            },
            { immediate: true } // Ensures the watcher runs immediately
        );

        return {
            nextUrl,
            previousUrl,
            indexDataFrom,
            indexDataTo,
            results_count,
            loadNext,
            loadPrev,
            getIndexingDetails,
        };
    },
};
</script>

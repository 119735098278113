<template>
  <div class="page page-center">
    <div class="container-tight py-4">
      <div class="text-center mb-4">
        <img src="@/assets/logo.png" height="80" alt="" class="navbar-brand-autodark" />
      </div>
      <form class="card card-md" v-on:submit.prevent autocomplete="off">
        <div class="card-body">
          <h2 class="card-title text-center mb-4">الدخول إلى النظام</h2>
          <div v-if="form_errors" class="invalid-feedback">
            {{ form_errors.non_field_errors }}
          </div>
          <div v-if="!token" class="mb-3">
            <label class="form-label">اسم المستخدم</label>
            <input
              autocomplete="off"
              type="text"
              class="form-control mb-2"
              :class="form_errors.username ? 'is-invalid' : ''"
              placeholder="أدخل اسم المستخدم"
              v-model="username"
            />
            <div v-if="form_errors" class="invalid-feedback">
              {{ form_errors.username?.[0] }}
            </div>
          </div>
          <div v-if="!token" class="mb-2">
            <label class="form-label">كلمة المرور</label>
            <input
              type="password"
              class="form-control mb-2"
              :class="form_errors.password ? 'is-invalid' : ''"
              placeholder="أدخل كلمة المرور"
              autocomplete="off"
              v-model="password"
            />
            <div v-if="form_errors" class="invalid-feedback">
              {{ form_errors.password?.[0] }}
            </div>
          </div>

          <div v-if="token && !is_add_device" class="mb-3">
            <label class="form-label">رمز OTP </label>
            <input
              autocomplete="off"
              type="text"
              class="form-control mb-2"
              :class="form_errors.otp ? 'is-invalid' : ''"
              v-model="otp"
            />
            <div v-if="form_errors" class="invalid-feedback">
              {{ form_errors.otp?.[0] }}
            </div>
          </div>

          <div v-if="qr" class="mb-3">
            <vue-qrcode :value="qr" :height="200"></vue-qrcode>
          </div>

          <div class="form-footer">
            <button
              v-if="!token"
              type="submit"
              @click="login"
              class="is-invalid btn btn-primary w-100"
            >
              دخول
            </button>
            <button
              v-if="token && !is_add_device"
              type="submit"
              @click="device_confirm"
              class="btn btn-primary w-100"
            >
              دخول
            </button>
            <button
              v-if="is_add_device"
              type="submit"
              @click="add_device"
              class="btn btn-primary w-100"
            >
              اضف جهاز+
            </button>
            <div v-if="form_errors" class="invalid-feedback">
              {{ form_errors.non_field_errors }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { ref, getCurrentInstance } from "vue";
import AuthService from "@/services/AuthService.js";
import VueQrcode from "vue-qrcode";

export default {
  components: { VueQrcode },
  setup() {
    // Accessing axios and other global properties
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios;

    // Reactive state
    const username = ref("");
    const password = ref("");
    const otp = ref("");
    const form_errors = ref([]);
    const token = ref("");
    const user = ref({});
    const is_add_device = ref(false);
    const qr = ref("");

    // Methods
    const login = async () => {
      proxy.$store.dispatch("logout"); // Dispatch logout action

      const credentials = {
        username: username.value,
        password: password.value,
      };

      const response = await AuthService.login(credentials, axios);

      if (proxy.$store.getters.getHttpError) {
        let error_obj = proxy.$store.getters.getHttpError;
        if (error_obj.response.data) form_errors.value = error_obj.response.data;
      } else {
        token.value = response.data.token;
        is_add_device.value = response.data.is_add_device;
      }
    };

    const device_confirm = async () => {
      const credentials = {
        token: token.value,
        otp: otp.value,
      };
      const response = await AuthService.device_confirm(credentials, axios);

      if (proxy.$store.getters.getHttpError) {
        let error_obj = proxy.$store.getters.getHttpError;
        if (error_obj.response.data) form_errors.value = error_obj.response.data;
      } else {
        user.value = response.data.user;
        proxy.$store.dispatch("login", { token: token.value, user: user.value });
        proxy.$router.push("/");
      }
    };

    const add_device = async () => {
      const credentials = {
        token: token.value,
      };
      const response = await AuthService.add_device(credentials, axios);

      if (proxy.$store.getters.getHttpError) {
        let error_obj = proxy.$store.getters.getHttpError;
        if (error_obj.response.data) form_errors.value = error_obj.response.data;
      } else {
        qr.value = response;
        is_add_device.value = false;
      }
    };

    // Returning state, methods, and reactive properties to the template
    return {
      username,
      password,
      otp,
      form_errors,
      token,
      user,
      is_add_device,
      qr,
      login,
      device_confirm,
      add_device,
    };
  },
};
</script>

<template>
  <div class="page page-center">
    <div class="container-tight py-4">
      <div class="text-center mb-4">
        <img src="@/assets/logo.png" height="80" alt="" class="navbar-brand-autodark" />
      </div>
      <form
        v-if="!is_authenticated"
        v-on:submit.prevent="submitForm"
        class="card card-md"
        autocomplete="off"
      >
        <div class="card-body">
          <h2 class="card-title text-center mb-4">معاينة تقرير طبي</h2>
          <div class="mb-3">
            <label class="form-label">ID</label>
            <input
              autocomplete="off"
              :class="form_errors.detail ? 'is-invalid' : ''"
              v-model="form.issue_uid"
              type="number"
              class="form-control mb-2"
              placeholder="أدخل معرّف التقرير المكون من 14 أرقام"
            />
          </div>
          <div class="mb-2">
            <label class="form-label">PIN</label>
            <input
              type="number"
              :class="form_errors.detail ? 'is-invalid' : ''"
              v-model="form.pin"
              class="form-control mb-2"
              placeholder="أدخل رمز الأمان المكون من 5 أرقام"
              autocomplete="off"
            />
            <div class="invalid-feedback">{{ form_errors.detail }}</div>
          </div>
          <div class="form-footer">
            <button type="submit" class="btn btn-primary w-100">معاينة</button>
          </div>
        </div>
      </form>
      <div v-else class="card card-md">
        <div class="card-body">
          <div class="row">
            <div class="col-12 text-center">
              <img
                class="avatar avatar-xl"
                style="height: auto"
                :src="issue.client_photo"
              />
            </div>
            <div class="col-12 mt-4">
              <h4 class="card-title m-0 mb-3">الاسم: {{ issue.client_name }}</h4>
              <h4 class="card-title m-0">رقم الجوال: {{ issue.client_mobile }}</h4>
              <h4 class="card-title m-0">تاريخ الفحص: {{ issue.report_date }}</h4>
              <h4 class="card-title m-0">اسم المكتب: {{ issue.office_name }}</h4>
              <h4 class="card-title m-0">رقم الطلب: {{ issue.application_no_out }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<script>
import { ref, computed, onMounted, getCurrentInstance } from "vue";

export default {
  components: {},
  setup() {
    // Accessing global properties (axios, store)
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // Access axios from global properties
    const store = proxy.$store; // Access Vuex store
    const router = proxy.$router; // Access Vue Router

    // Reactive state
    const form = ref({
      issue_uid: "",
      pin: "",
    });

    const form_errors = ref({
      detail: "",
    });

    const issue = ref({});

    // Computed properties
    const is_authenticated = computed(() => {
      return store.getters.getReportPin !== "";
    });

    // Methods
    const submitForm = async () => {
      if (!form.value.issue_uid || !form.value.pin) {
        form_errors.value.detail = "الكود ورمز التحقق لايمكن ان يكونا فارغين!";
        return;
      }

      const authReportPin = form.value.pin;
      try {
        const response = await axios.post(
          `/api/report-issue/${form.value.issue_uid || 0}/report_out/`,
          { pin: authReportPin }
        );
        if (store.getters.getHttpError) {
          let error_obj = store.getters.getHttpError;
          if (error_obj.response.data) form_errors.value = error_obj.response.data;
        } else {
          store.dispatch("authReportPin", authReportPin);
          issue.value = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(() => {
      form.value.issue_uid = router.currentRoute.value.params.uid;
      store.dispatch("authReportPin", "");
    });

    return {
      form,
      form_errors,
      issue,
      is_authenticated,
      submitForm,
    };
  },
};
</script>

<template>
    <div>
        <BarChart v-if="testData" :chartData="testData" :chartOptions="chartOptions" />
        <div v-else>Loading chart data...</div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';

Chart.register(...registerables);

export default ({
    name: 'ClientsChart',
    components: { BarChart },
    setup() {
        const testData = ref(null);
        const chartOptions = ref({
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'الإحصائيات (آخر 30 يوم)',
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'التواريخ',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'القيم',
                    },
                },
            },
        });

        const fetchChartData = async () => {
            try {
                const response = await axios.get('/api/count-per-day/');
                const data = response.data;

                // Format the dates
                const formattedDates = data.xaxis.categories.map(date =>
                    new Date(date).toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
                );

                // Prepare the datasets
                const datasets = data.series.map(series => ({
                    label: series.name,
                    data: series.data,
                    backgroundColor: series.name === 'التقارير'
                        ? '#FEBC4B'
                        : series.name === 'العملاء'
                            ? '#40A0FC'
                            : '#50E7A6',
                }));

                // Update chart data
                testData.value = {
                    labels: formattedDates,
                    datasets: datasets,
                };
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
        };

        onMounted(() => {
            fetchChartData();
        });

        return { testData, chartOptions };
    },
});
</script>
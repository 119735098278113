<template>
  <SideBar />

  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 class="page-title">احصائيات العملاء</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="col-12">
            <div class="card">
              <div class="card-body border-bottom py-3">
                <div>
                  <div class="text-muted">
                    المكتب
                    <div class="col-12 col-lg-2 mx-2 mb-2 d-inline-block">
                      <v-select
                        v-model="selected_office"
                        :options="offices"
                        :searchable="true"
                        label="name"
                        placeholder="ابحث عن المكتب"
                        @search="searchOffices"
                      />
                    </div>
                    المندوب
                    <div class="col-12 col-lg-2 mx-2 mb-2 d-inline-block">
                      <v-select
                        v-model="selected_agent"
                        :options="agents"
                        :searchable="true"
                        label="name"
                        placeholder="ابحث عن  مندوب"
                        @search="searchAgents"
                      />
                    </div>
                    من
                    <div class="col-12 col-lg-2 mx-2 d-inline-block">
                      <input
                        autocomplete="off"
                        v-model="date_from"
                        type="datetime-local"
                        class="form-control"
                        placeholder="من"
                        max="9999-12-31"
                      />
                    </div>
                    إلى
                    <div class="col-12 col-lg-2 mx-2 d-inline-block">
                      <input
                        autocomplete="off"
                        v-model="date_to"
                        type="datetime-local"
                        class="form-control"
                        placeholder="إلى"
                        max="9999-12-31"
                      />
                    </div>
                    <button
                      type="button"
                      @click="onfilterClick()"
                      class="btn btn-primary"
                    >
                      ترشيح
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin: 0 0.5rem 0 -0.25rem"
                        class="icon icon-tabler icon-tabler-filter"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-vcenter text-nowrap table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="text-right">#</th>

                      <th class="text-right">العميل</th>
                      <th class="text-right">رقم الجواز</th>
                      <th class="text-right">تاريخ الانشاء</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="client in clients" :key="client.id">
                      <td class="text-right">
                        {{ client.index }}
                      </td>
                      <td class="text-right">
                        {{ client.name }}
                      </td>
                      <td class="text-right log-verb">{{ client.passport_no }} <br /></td>
                      <td class="text-right">
                        {{ formate_datetime(client.created) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- pagination -->
              <PaginationBar :pagination_data="pagination_data" />
            </div>
          </div>
        </div>
      </div>
      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>
<style>
.log-verb {
  white-space: normal;
}
</style>
<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";
import PaginationBar from "@/components/PaginationBar.vue";
import { formate_datetime } from "@/script.js";

import vSelect from "vue3-select";
import "vue3-select/dist/vue3-select.css";

export default {
  components: {
    FooterBar,
    SideBar,
    PaginationBar,
    vSelect,
  },
  setup() {
    // Accessing axios, router, and store via proxy
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // access axios from global properties
    const router = proxy.$router;
    const store = proxy.$store;

    // Reactive state
    const selected_office = ref("");
    const selected_agent = ref("");

    const officeSearchQuery = ref("");
    const agentSearchQuery = ref("");

    const offices = ref([]);
    const agents = ref([]);
    const date_from = ref(formate_datetime(new Date(Date.now() - 24 * 60 * 60 * 1000)));
    const date_to = ref(formate_datetime(new Date()));
    const dataUrl = "/api/client-by/";
    const pagination_data = ref({});
    const urlArgs = ref("");
    const clients = ref([]);

    // Methods

    const addParamIfExists = (params, key, value) => {
      if (value && value !== "") {
        params.append(key, value);
      }
    };

    const onfilterClick = async () => {
      const params = new URLSearchParams();

      addParamIfExists(params, "office", selected_office.value?.id);
      addParamIfExists(params, "office_name", selected_office.value?.name);
      addParamIfExists(params, "agent_name", selected_agent.value?.name);
      addParamIfExists(params, "agent", selected_agent.value?.id);
      addParamIfExists(params, "fromDate", date_from.value);
      addParamIfExists(params, "toDate", date_to.value);

      const urlArgsStr = `?${params.toString()}`;
      const updatedUrl = fixTimeZone(urlArgsStr);
      router.push(updatedUrl, { silent: true });
    };

    const fixTimeZone = (original_url) => {
      const params = new URLSearchParams(original_url);

      const fromDate = params.get("fromDate", "");
      const toDate = params.get("toDate", "");

      const isofromDate = fromDate ? new Date(fromDate).toISOString() : "";
      const isotoDate = toDate ? new Date(toDate).toISOString() : "";

      // Edit the fromDate parameter
      if (isofromDate) params.set("fromDate", isofromDate);
      if (isotoDate) params.set("toDate", isotoDate);

      // Reconstruct the URL with the updated query string
      const updatedUrl = `?${params.toString()}`;
      return updatedUrl;
    };

    const getData = async (urlArgs) => {
      const response = await axios.get(dataUrl + urlArgs);
      if (response) {
        clients.value = response.data.results;
        pagination_data.value = response.data;
      }
      if (store.getters.getHttpError) {
        // You may want to handle the error more robustly here
        console.error(store.getters.getHttpError.message);
      }
    };

    const searchOffices = async (officeSearchQuery) => {
      if (officeSearchQuery.length > 2) {
        try {
          const response = await axios.get("/api/office/active/", {
            params: { search: officeSearchQuery },
          });
          offices.value = response.data.results;
        } catch (error) {
          console.error("Error fetching offices:", error);
        }
      } else {
        offices.value = [];
      }
    };

    const searchAgents = async (agentSearchQuery) => {
      if (agentSearchQuery.length > 2) {
        try {
          const response = await axios.get("/api/agent/active/", {
            params: { search: agentSearchQuery },
          });
          agents.value = response.data.results;
        } catch (error) {
          console.error("Error fetching agents:", error);
        }
      } else {
        agents.value = [];
      }
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(async () => {
      urlArgs.value = window.location.search;
      await getData(urlArgs.value);

      if (router.currentRoute.value.query.office) {
        selected_office.value = {};
        selected_office.value.name = router.currentRoute.value.query.office_name;
        selected_office.value.id = router.currentRoute.value.query.office;
      }

      if (router.currentRoute.value.query.agent) {
        selected_agent.value = {};
        selected_agent.value.name = router.currentRoute.value.query.agent_name;
        selected_agent.value.id = router.currentRoute.value.query.agent;
      }
      if (router.currentRoute.value.query.fromDate) {
        date_from.value = formate_datetime(router.currentRoute.value.query.fromDate);
      }
      if (router.currentRoute.value.query.toDate) {
        date_to.value = formate_datetime(router.currentRoute.value.query.toDate);
      }
    });

    // Return state and methods to template
    return {
      formate_datetime,

      date_from,
      date_to,
      dataUrl,
      pagination_data,
      urlArgs,
      onfilterClick,
      fixTimeZone,
      getData,
      searchOffices,
      searchAgents,
      officeSearchQuery,
      selected_office,
      agentSearchQuery,
      selected_agent,
      offices,
      agents,
      clients,
    };
  },
};
</script>

<template>
  <aside class="navbar navbar-vertical navbar-expand-lg navbar-dark">
    <div class="container-fluid">
      <button
        @click="sideToggle()"
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar-menu"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark">
        <a href="/">
          <img
            src="@/assets/logo.png"
            height="80"
            alt="مستشفى صابر | الشهادات الطبية"
            class="navbar-brand navbar-brand-autodark"
          />
        </a>
      </h1>

      <div class="navbar-nav flex-row d-lg-none">
        <div class="nav-item d-none d-md-flex me-3"></div>
      </div>
      <div
        class="collapse navbar-collapse"
        :class="isMenuShowen ? 'show' : ''"
        id="navbar-menu"
      >
        <ul class="navbar-nav pt-lg-3">
          <li class="nav-item dropdown">
            <a
              @click="dropdownToggle()"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              aria-expanded="false"
            >
              <!-- <span class="nav-link-icon d-md-none d-lg-inline-block"> -->
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path><circle cx="12" cy="12" r="3"></circle></svg> -->
              <!-- </span> -->
              <span class="nav-link-title"
                >المستخدم: {{ username }}<br />
                <small v-if="role == 1">ADMIN</small>
                <small v-if="role == 3">Receptionist</small>
                <small v-if="role == 4">Lab</small>
                <small v-if="role == 5">Doctor</small>
              </span>
            </a>

            <!-- dropedown -->
            <div class="dropdown-menu" :class="{ show: isUserDropActive }">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column">
                  <button @click="logout()" class="dropdown-item">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-logout"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                        ></path>
                        <path d="M7 12h14l-3 -3m0 6l3 -3"></path>
                      </svg>
                    </span>
                    <span class="nav-link-title">تسجيل خروج</span>
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div class="dropdown-divider"></div>
          </li>

          <li class="nav-item" :class="{ active: $route.name === 'home' }">
            <router-link to="/" class="nav-link">
              <span class="nav-link-icon d-md-none d-lg-inline-block">
                <!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="5 12 3 12 12 3 21 12 19 12" />
                  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                </svg>
              </span>
              <span class="nav-link-title"> اللوحة الرئيسية </span>
            </router-link>
          </li>
          <template v-if="role == 1 || role == 5 || role == 4">
            <li class="nav-item" :class="{ active: $route.name === 'sample' }">
              <router-link to="/sample/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-test-pipe-2"
                    width="44"
                    height="44"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#ffffff"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 3v15a3 3 0 0 1 -6 0v-15" />
                    <path d="M9 12h6" />
                    <path d="M8 3h8" />
                  </svg>
                </span>
                <span class="nav-link-title"> العينات </span>
              </router-link>
            </li>
          </template>
          <template v-if="role == 1 || role == 5">
            <li class="nav-item" :class="{ active: $route.name === 'report' }">
              <router-link to="/report/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <!-- Download SVG icon from http://tabler-icons.io/i/clipboard-list -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                    />
                    <rect x="9" y="3" width="6" height="4" rx="2" />
                    <line x1="9" y1="12" x2="9.01" y2="12" />
                    <line x1="13" y1="12" x2="15" y2="12" />
                    <line x1="9" y1="16" x2="9.01" y2="16" />
                    <line x1="13" y1="16" x2="15" y2="16" />
                  </svg>
                </span>
                <span class="nav-link-title"> الفحوصات </span>
              </router-link>
            </li>
          </template>

          <li class="nav-item" :class="{ active: $route.name === 'client' }">
            <router-link to="/client/" class="nav-link">
              <span class="nav-link-icon d-md-none d-lg-inline-block">
                <!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-users"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                </svg>
              </span>
              <span class="nav-link-title"> قائمة العملاء </span>
            </router-link>
          </li>
          <template v-if="role == 1 || role == 3">
            <li class="nav-item" :class="{ active: $route.name === 'add_client' }">
              <router-link to="/client/add/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-user-plus"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    <path d="M16 11h6m-3 -3v6"></path>
                  </svg>
                </span>
                <span class="nav-link-title"> إضافة عميل جديد </span>
              </router-link>
            </li>
          </template>

          <template v-if="role == 1">
            <li class="nav-item">
              <div class="dropdown-divider"></div>
            </li>
            <li class="nav-item" :class="{ active: $route.name.includes('user') }">
              <router-link to="/admin/users/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-users"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                  </svg>
                </span>
                <span class="nav-link-title"> المستخدمين </span>
              </router-link>
            </li>
            <li class="nav-item" :class="{ active: $route.name.includes('logs') }">
              <router-link to="/admin/logs/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <!-- Download SVG icon from http://tabler-icons.io/i/notes -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="5" y="3" width="14" height="18" rx="2" />
                    <line x1="9" y1="7" x2="15" y2="7" />
                    <line x1="9" y1="11" x2="15" y2="11" />
                    <line x1="9" y1="15" x2="13" y2="15" />
                  </svg>
                </span>
                <span class="nav-link-title"> السجلات </span>
              </router-link>
            </li>
            <li class="nav-item" :class="{ active: $route.name.includes('moderations') }">
              <router-link to="/admin/moderations/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <!-- Download SVG icon from http://tabler-icons.io/i/checkup-list -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                    />
                    <rect x="9" y="3" width="6" height="4" rx="2" />
                    <path d="M9 14h.01" />
                    <path d="M9 17h.01" />
                    <path d="M12 16l1 1l3 -3" />
                  </svg>
                </span>
                <span class="nav-link-title"> عمليات التعديل </span>
              </router-link>
            </li>
            <li class="nav-item" :class="{ active: $route.name.includes('block_list') }">
              <router-link to="/admin/block-list/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M14.274 10.291a4 4 0 1 0 -5.554 -5.58m-.548 3.453a4.01 4.01 0 0 0 2.62 2.65"
                    />
                    <path
                      d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 1.147 .167m2.685 2.681a4 4 0 0 1 .168 1.152v2"
                    />
                    <line x1="3" y1="3" x2="21" y2="21" />
                  </svg>
                </span>
                <span class="nav-link-title"> قائمة الحظر </span>
              </router-link>
            </li>
          </template>

          <template v-if="role == 1">
            <li class="nav-item">
              <div class="dropdown-divider"></div>
            </li>
            <li class="nav-item" :class="{ active: $route.name.includes('office') }">
              <router-link to="/office/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <!-- Download SVG icon from http://tabler-icons.io/i/archive -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="3" y="4" width="18" height="4" rx="2" />
                    <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                    <line x1="10" y1="12" x2="14" y2="12" />
                  </svg>
                </span>
                <span class="nav-link-title"> المكاتب </span>
              </router-link>
            </li>
            <li class="nav-item" :class="{ active: $route.name.includes('agent') }">
              <router-link to="/agent/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <!-- Download SVG icon from http://tabler-icons.io/i/notes -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="5" y="3" width="14" height="18" rx="2" />
                    <line x1="9" y1="7" x2="15" y2="7" />
                    <line x1="9" y1="11" x2="15" y2="11" />
                    <line x1="9" y1="15" x2="13" y2="15" />
                  </svg>
                </span>
                <span class="nav-link-title"> المندوبين </span>
              </router-link>
            </li>

            <li
              class="nav-item"
              :class="{ active: $route.name.includes('client-statistics') }"
            >
              <router-link to="/admin/client-statistics/" class="nav-link">
                <span class="nav-link-icon d-md-none d-lg-inline-block">
                  <!-- Download SVG icon from http://tabler-icons.io/i/chart-bar -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="3" y="12" width="6" height="8" rx="1" />
                    <rect x="9" y="8" width="6" height="12" rx="1" />
                    <rect x="15" y="4" width="6" height="16" rx="1" />
                    <line x1="4" y1="20" x2="18" y2="20" />
                  </svg>
                </span>
                <span class="nav-link-title"> احصائيات </span>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
import { ref, computed, getCurrentInstance } from "vue";
import AuthService from "@/services/AuthService.js";

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const store = proxy.$store;
    const router = proxy.$router;

    // Reactive state
    const isUserDropActive = ref(false);
    const isMenuShowen = ref(false);

    // Computed properties to replace Options API's computed
    const username = computed(() => store.getters.getUser.username);
    const role = computed(() => store.getters.getUser.role);
    const isLoggedIn = computed(() => store.getters.isLoggedIn);

    // Methods
    const logout = async () => {
      await AuthService.logout(proxy.axios);
      store.dispatch("logout");
      router.push("/login/");
    };

    const dropdownToggle = () => {
      isUserDropActive.value = !isUserDropActive.value;
    };

    const sideToggle = () => {
      isMenuShowen.value = !isMenuShowen.value;
    };

    return {
      isUserDropActive,
      isMenuShowen,
      username,
      role,
      isLoggedIn,
      logout,
      dropdownToggle,
      sideToggle,
    };
  },
};
</script>

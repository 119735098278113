<template>
    <SideBar />
    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                قائمة الحظر
                            </h2>
                        </div>
                        <!-- Page title actions -->

                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">

                    <div class="col-12">
                        <div class="card">
                            <SearchBar :dataUrl=searchUrl :legends=false />

                            <div class="table-responsive">
                                <table class="table card-table table-vcenter text-nowrap datatable table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-right">الاسم
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-sm text-dark icon-thick" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="6 15 12 9 18 15" />
                                                </svg>
                                            </th>
                                            <th class="text-right">رقم الجواز</th>
                                            <th class="text-right">ملاحظة</th>
                                            <th class="text-right">آخر تعديل</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="client in clients" :key="client.id">
                                            <td @click="$router.push({ name: 'detail_client', params: { id: client.id } })"
                                                class="cursor-pointer text-right">
                                                {{ client.name }}
                                            </td>
                                            <td class="text-right">
                                                {{ client.passport_no }}
                                            </td>
                                            <td>
                                                {{ client.notes }}
                                            </td>
                                            <td class="text-right">
                                                {{ client.modified }}
                                            </td>
                                            <td class="text-start">

                                                <div class="btn-list flex-nowrap">
                                                    <button class="btn d-sm-inline-block"
                                                        @click="$router.push({ name: 'detail_client', params: { id: client.id } })">
                                                        <span class="mx-1">عرض</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="icon icon-tabler icon-tabler-arrow-left" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                            <line x1="5" y1="12" x2="11" y2="18"></line>
                                                            <line x1="5" y1="12" x2="11" y2="6"></line>
                                                        </svg>
                                                    </button>
                                                    <button class="btn d-sm-inline-block"
                                                        @click="unblock_client(client.id)">
                                                        <span class="mx-1">الغاء الحظر</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="icon icon-tabler icon-tabler-edit" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path
                                                                d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1">
                                                            </path>
                                                            <path
                                                                d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z">
                                                            </path>
                                                            <path d="M16 5l3 3"></path>
                                                        </svg>
                                                    </button>

                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <!-- pagination -->
                            <PaginationBar :pagination_data=pagination_data />
                        </div>
                    </div>

                </div>
            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>


<style>
.users .dropdown-menu {
    inset: auto auto 0px 0px;

}
</style>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue';
import PaginationBar from '@/components/PaginationBar.vue';
import SearchBar from '@/components/SearchBar.vue';
import SideBar from '@/components/SideBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import { formate_datetime } from '@/script.js';

export default {
    components: {
        PaginationBar,
        SearchBar,
        SideBar,
        FooterBar,
    },
    setup() {
        // Accessing axios and Vuex store from global properties
        const { proxy } = getCurrentInstance();
        const axios = proxy.axios; // Access axios from global properties
        const store = proxy.$store;

        // Reactive state
        const clients = ref([]);
        const dataUrl = '/api/client/blocked_clients/';
        const searchUrl = '/admin/block-list/';
        const pagination_data = ref({});
        const urlArgs = ref('');
        const msg = ref(''); // Define msg as a reactive variable
        const form_errors = ref({}); // Define form_errors as a reactive variable

        // Methods
        const getData = async (url) => {
            const response = await axios.get(url);
            if (response) {
                clients.value = response.data.results;
                pagination_data.value = response.data;
                const urlParams = new URL(response.request.responseURL);
                urlArgs.value = urlParams.search;
            }
            if (store.getters.getHttpError) {
                // Handle error
                msg.value = store.getters.getHttpError.message;
            }
        };

        const unblock_client = async (id) => {
            if (confirm("الغاء حظر العميل؟") !== true) return false;

            const url = `/api/client/${id}/unblock/`;
            await axios.post(url, { status: 1 })
                .then(() => {
                    // Success Action
                    if (store.getters.getHttpError) {
                        const error_obj = store.getters.getHttpError;
                        msg.value = error_obj.message;
                        if (error_obj.response.data) form_errors.value = error_obj.response.data;
                    }
                    // Remove the client from the list
                    clients.value = clients.value.filter(client => client.id !== id);
                });
        };

        // Lifecycle hook equivalent to 'created'
        onMounted(() => {
            urlArgs.value = window.location.search;
            getData(dataUrl + urlArgs.value);
        });

        // Returning state and methods to template
        return {
            formate_datetime,
            clients,
            pagination_data,
            urlArgs,
            msg, // Make msg available in the template
            form_errors, // Make form_errors available in the template
            getData,
            unblock_client,
            searchUrl, // Make searchUrl available in the template
        };
    },
};
</script>

import moment from 'moment';

export function removeTime(date) {

  if (date instanceof Date) return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  return date;

}

export function formate_datetime(date) {
  const a = moment(date);
  if (a.isValid()) {
    // Option 1: December 26, 2024 5:06 PM
    // return a.format('MMMM D, YYYY h:mm A');

    // Option 2: 26 Dec 2024 17:06
    //return a.format('D MMM YYYY HH:mm');

    // Option 3: Thu, Dec 26, 2024 5:06 PM
    // return a.format('ddd, MMM D, YYYY h:mm A');
    // return a.format('YYYY-MM-DD  HH:mm'); 
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');

  }
  return date;

}

export function isTauri() {
  return typeof window !== 'undefined' && window.__TAURI__ !== undefined;
}

export function get_date(date) {
  const formattedDate = new Intl.DateTimeFormat('en-CA').format(new Date(date));
  return formattedDate
}


export function get_issue_uid(val) {
  // Check if val is a string and matches the regex
  const matches = typeof val === 'string' ? val.match(/.{1,3}/g) : null;
  // Return the joined string if matches is not null, otherwise return an empty string or fallback value
  return matches ? matches.join('-') : '';
}
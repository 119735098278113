<template>
  <SideBar />

  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 v-if="form.id" class="page-title">طول عميل</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="row row-cards">
            <div class="col-12">
              <form v-on:submit.prevent="submitForm" class="card card-md">
                <div v-if="form_errors" class="invalid-feedback">
                  {{ form_errors.non_field_errors }}
                </div>
                <div class="card-header">
                  <h4 class="card-title">بيانات العميل</h4>
                </div>
                <div class="card-body">
                  <div v-if="form?.moderation_status == 0" class="row">
                    <div class="col-12">
                      <div class="alert alert-info alert-dismissible" role="alert">
                        <div class="d-flex">
                          <div>
                            <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                            <!-- SVG icon code with class="alert-icon" -->
                          </div>
                          <div>
                            <h4 class="alert-title">تحت الموافقة</h4>
                            <div class="text-muted">
                              هذا العنصر لازال يتطلب موافقة المدير على التعديلات
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <label class="form-label">{{ form.name }}</label>
                      <fieldset class="form-fieldset col-12 mb-4">
                        <div class="row divide-y">
                          <div class="col-12 mb-3">
                            <label class="form-label">طول العميل</label>
                            <div class="input-group mb-2">
                              <input
                                autocomplete="off"
                                class="form-control text-right"
                                :class="form_errors.height ? 'is-invalid' : ''"
                                v-model="form.height"
                                type="number"
                              />
                              <span class="input-group-text"> سنتيمتر </span>
                              <div class="invalid-feedback">
                                {{ form_errors.height?.[0] }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div class="card-footer text-end">
                  <div class="d-flex order-reversed">
                    <a href="#" onclick="history.back()" class="btn">إلغاء</a>
                    <button
                      :disabled="!isFormChanged"
                      type="submit"
                      class="btn btn-primary ms-auto"
                    >
                      حفظ
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, getCurrentInstance } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";

export default {
  components: {
    FooterBar,
    SideBar,
  },
  setup() {
    // Accessing global properties
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // Access axios from global properties

    // Reactive state
    const form = ref({
      blood_test_uid: "",
      height: "",
      client: "",
      has_report: false,
    });

    const msg = ref("");
    const form_errors = ref([]);
    const initialForm = ref(null);

    // Computed property for checking if the form has changed
    const isFormChanged = computed(() => {
      return JSON.stringify(form.value) !== JSON.stringify(initialForm.value);
    });

    // Methods
    const submitForm = async () => {
      let post_method = axios.post;
      let url = `/api/sample/`;

      if (form.value.blood_test_uid) {
        post_method = axios.put;
        url = `/api/sample/${form.value.blood_test_uid}/`;
      }

      try {
        const response = await post_method(url, form.value);
        if (proxy.$store.getters.getHttpError) {
          let error_obj = proxy.$store.getters.getHttpError;
          msg.value = error_obj.message;
          if (error_obj.response.data) form_errors.value = error_obj.response.data;
        } else {
          proxy.$router.replace({
            name: "examination_label",
            params: { height_blood_sample_uid: response.data.blood_test_uid },
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getData = async () => {
      if (form.value.blood_test_uid) {
        try {
          const response = await axios.get(`/api/sample/${form.value.blood_test_uid}/`);
          if (response) {
            form.value.height = response.data.height;
            form.value.moderation_status = response.data.moderation_status;
            form.value.has_report = response.data.has_report;
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (proxy.$store.getters.getHttpError) {
        msg.value = proxy.$store.getters.getHttpError.message;
      }
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(async () => {
      form.value.blood_test_uid = proxy.$route.params.blood_test_uid;
      form.value.client = proxy.$route.params.id;
      await getData();
      initialForm.value = { ...form.value };
    });

    // Return values and methods for template
    return {
      form,
      msg,
      form_errors,
      isFormChanged,
      submitForm,
      getData,
    };
  },
};
</script>

<template>

    <span v-click-away="onClickAway" class="dropdown">
        <button @click="toggle" class="btn dropdown-toggle align-text-top" aria-expanded="false">{{ count }}</button>
        <div :class="active ? 'show' : ''" class="dropdown-menu dropdown-menu-end">
            <slot>
            </slot>

        </div>
    </span>
</template>

<script>
import { ref } from 'vue';

export default {
    props: {
        count: {
            required: true
        }
    },
    setup() {
        // Reactive state
        const active = ref(false);

        // Methods
        const toggle = () => {
            active.value = !active.value;
        };

        const onClickAway = () => {
            if (active.value) {
                active.value = false;
            }
        };

        // Return reactive state and methods to the template
        return {
            active,
            toggle,
            onClickAway
        };
    }
};
</script>

<template>
  <SideBar />

  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 v-if="form.id" class="page-title">تعديل مكتب</h2>
              <h2 v-else class="page-title">إضافة مكتب جديد</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="row row-cards">
            <div class="col-12">
              <form v-on:submit.prevent="submitForm" class="card card-md">
                <div v-if="form_errors" class="invalid-feedback">
                  {{ form_errors.non_field_errors }}
                </div>
                <div class="card-header">
                  <h4 class="card-title">بيانات المكتب</h4>
                </div>
                <div class="card-body">
                  <div v-if="form.id && form.moderation_status == 0" class="row">
                    <div class="col-12">
                      <div class="alert alert-info alert-dismissible" role="alert">
                        <div class="d-flex">
                          <div>
                            <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                            <!-- SVG icon code with class="alert-icon" -->
                          </div>
                          <div>
                            <h4 class="alert-title">تحت الموافقة</h4>
                            <div class="text-muted">
                              هذا العنصر لازال يتطلب موافقة المدير على التعديلات
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <label class="form-label">البيانات الأساسية</label>
                      <fieldset class="form-fieldset col-12 mb-4">
                        <div class="row divide-y">
                          <div class="col-12 mb-3">
                            <label class="form-label">الاسم</label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.name ? 'is-invalid' : ''"
                              v-model="form.name"
                              placeholder="الأسم"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.name?.[0] }}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div class="card-footer text-end">
                  <div class="d-flex order-reversed">
                    <!-- <a href="#" class="btn btn-link">إلغاء</a> -->
                    <button type="submit" class="btn btn-primary ms-auto">حفظ</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";

export default {
  components: {
    FooterBar,
    SideBar,
  },
  setup() {
    // Accessing axios from global properties
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // Now you can use axios

    // Reactive state
    const form = ref({
      id: "",
      name: "",
      moderation_status: "",
    });
    const msg = ref("");
    const form_errors = ref([]);

    // Method to submit form
    const submitForm = async () => {
      let post_method = axios.post;
      let url = `/api/office/`;

      if (form.value.id) {
        post_method = axios.put;
        url = `/api/office/${form.value.id}/`;
      }

      try {
        await post_method(url, form.value);
        // Perform Success Action
        if (proxy.$store.getters.getHttpError) {
          let error_obj = proxy.$store.getters.getHttpError;
          msg.value = error_obj.message;
          if (error_obj.response.data) form_errors.value = error_obj.response.data;
        } else {
          proxy.$router.replace("/office/");
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Method to fetch data
    const getData = async () => {
      if (form.value.id) {
        try {
          const response = await axios.get(`/api/office/${form.value.id}/`);
          if (response) form.value = response.data;
        } catch (error) {
          console.error(error);
        }
      }

      if (proxy.$store.getters.getHttpError) {
        msg.value = proxy.$store.getters.getHttpError.message;
      }
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(() => {
      form.value.id = proxy.$route.params.id;
      getData();
    });

    return {
      form,
      msg,
      form_errors,
      submitForm,
      getData,
    };
  },
};
</script>

<template>
  <SideBar />

  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 class="page-title">سجل حركة المستخدمين</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="col-12">
            <div class="card">
              <!-- <div class="card-header"> -->
              <!-- <h3 class="card-title">العملاء</h3> -->
              <!-- </div> -->
              <div class="card-body border-bottom py-3">
                <div>
                  <div class="text-muted">
                    السجل
                    <div class="col-12 col-lg-2 mx-2 mb-2 d-inline-block">
                      <select v-model="log_type" name="filter-user" class="form-select">
                        <option value="" selected="">- جميع السجلات -</option>
                        <option value="logged">عمليات الدخول والخروج</option>
                        <option value="test">عمليات الفحوصات</option>
                        <option value="searched">عمليات البحث للمستخدمين</option>
                        <option value="report was viewed">
                          التحقق الخارجي من التقارير
                        </option>
                      </select>
                    </div>
                    المستخدم
                    <div class="col-12 col-lg-2 mx-2 mb-2 d-inline-block">
                      <select v-model="actor" name="filter-user" class="form-select">
                        <option value="" selected="">- جميع المستخدمين -</option>
                        <option v-for="user in users" :key="user.id" :value="user.id">
                          {{ user.username }}
                        </option>
                      </select>
                    </div>
                    من
                    <div class="col-12 col-lg-2 mx-2 d-inline-block">
                      <input
                        autocomplete="off"
                        v-model="date_from"
                        type="datetime-local"
                        class="form-control"
                        placeholder="من"
                        max="9999-12-31"
                      />
                    </div>
                    إلى
                    <div class="col-12 col-lg-2 mx-2 d-inline-block">
                      <input
                        autocomplete="off"
                        v-model="date_to"
                        type="datetime-local"
                        class="form-control"
                        placeholder="إلى"
                        max="9999-12-31"
                      />
                    </div>
                    <button
                      type="button"
                      @click="onfilterClick()"
                      class="btn btn-primary"
                    >
                      ترشيح
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin: 0 0.5rem 0 -0.25rem"
                        class="icon icon-tabler icon-tabler-filter"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-vcenter text-nowrap table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="text-right">المستخدم</th>
                      <th class="text-right">الوصف</th>
                      <th class="text-right">العنصر</th>
                      <th>الوقت</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="log in logs" :key="log.id">
                      <td class="text-right">
                        {{ log.actor }}
                      </td>
                      <td class="text-right log-verb">
                        {{ log.verb }} <br />
                        <template v-if="log.action_object">
                          {{ log.action_object }} <br />
                        </template>
                        - {{ log.timesince }}
                      </td>
                      <td class="text-right">
                        <a href="#" @click.prevent="$router.push(log.target_url)">
                          {{ log.target }}
                        </a>
                      </td>
                      <td class="text-right">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-calendar-event text-muted"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                          <line x1="16" y1="3" x2="16" y2="7"></line>
                          <line x1="8" y1="3" x2="8" y2="7"></line>
                          <line x1="4" y1="11" x2="20" y2="11"></line>
                          <rect x="8" y="15" width="2" height="2"></rect>
                        </svg>
                        {{ formate_datetime(log.timestamp) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- pagination -->
              <PaginationBar :pagination_data="pagination_data" />
            </div>
          </div>
        </div>
      </div>
      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>
<style>
.log-verb {
  white-space: normal;
}
</style>
<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";
import PaginationBar from "@/components/PaginationBar.vue";
import { formate_datetime } from "@/script.js";

export default {
  components: {
    FooterBar,
    SideBar,
    PaginationBar,
  },
  setup() {
    // Accessing axios, router, and store via proxy
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // access axios from global properties
    const router = proxy.$router;
    const store = proxy.$store;

    // Reactive state
    const logs = ref([]);
    const users = ref([]);
    const actor = ref("");
    const log_type = ref("");
    const date_from = ref(formate_datetime(new Date(Date.now() - 24 * 60 * 60 * 1000)));
    const date_to = ref(formate_datetime(new Date()));
    const dataUrl = "/api/activity/";
    const pagination_data = ref({});
    const urlArgs = ref("");

    // Methods
    const onfilterClick = async () => {
      let urlArgsStr = `?actor=${actor.value}&search=${log_type.value}&fromDate=${date_from.value}&toDate=${date_to.value}`;
      const updatedUrl = fixTimeZone(urlArgsStr);
      router.push(updatedUrl, { silent: true });
    };

    const fixTimeZone = (original_url) => {
      const params = new URLSearchParams(original_url);

      const fromDate = params.get("fromDate", "");
      const toDate = params.get("toDate", "");

      const isofromDate = fromDate ? new Date(fromDate).toISOString() : "";
      const isotoDate = toDate ? new Date(toDate).toISOString() : "";

      // Edit the fromDate parameter
      if (isofromDate) params.set("fromDate", isofromDate);
      if (isotoDate) params.set("toDate", isotoDate);

      // Reconstruct the URL with the updated query string
      const updatedUrl = `?${params.toString()}`;
      return updatedUrl;
    };

    const getData = async (urlArgs) => {
      const response = await axios.get(dataUrl + urlArgs);
      if (response) {
        logs.value = response.data.results;
        pagination_data.value = response.data;
      }
      if (store.getters.getHttpError) {
        // You may want to handle the error more robustly here
        console.error(store.getters.getHttpError.message);
      }
    };

    const getUsers = async () => {
      const response = await axios.get("/api/user/");
      if (response) {
        users.value = response.data.results;
      }
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(async () => {
      urlArgs.value = window.location.search;
      await getData(urlArgs.value);
      await getUsers();

      actor.value = router.currentRoute.value.query.actor || "";
      log_type.value = router.currentRoute.value.query.search || "";

      if (router.currentRoute.value.query.fromDate) {
        date_from.value = formate_datetime(router.currentRoute.value.query.fromDate);
      }
      if (router.currentRoute.value.query.toDate) {
        date_to.value = formate_datetime(router.currentRoute.value.query.toDate);
      }
    });

    // Return state and methods to template
    return {
      formate_datetime,
      logs,
      users,
      actor,
      log_type,
      date_from,
      date_to,
      dataUrl,
      pagination_data,
      urlArgs,
      onfilterClick,
      fixTimeZone,
      getData,
      getUsers,
    };
  },
};
</script>

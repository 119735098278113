<template>
  <transition name="slide-fade">
    <tr v-if="finish_mod" :class="item_class">
      <td class="text-right">
        {{ moderation_object.name }}
      </td>
      <td class="text-right">
        {{ moderation_object.model_type }}
      </td>

      <td class="text-right">
        {{ moderation_object.status }}
      </td>
      <td class="text-right">
        {{ moderation_object.created_by }}
      </td>
      <td class="text-right">
        {{ formate_datetime(moderation_object.created_at) }}
      </td>

      <td>
        <div class="btn-list flex-nowrap">
          <button
            @click="dropdownToggle()"
            class="btn btn-light d-sm-inline-block"
            aria-expanded="false"
            role="tab"
          >
            <span class="">عرض تفاصيل التعديل..</span>
          </button>

          <a
            v-if="moderation_object.status == 'pending'"
            @click="approve(moderation_object.id)"
            class="btn btn-primary d-sm-inline-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-check"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M5 12l5 5l10 -10"></path>
            </svg>
            <span class="">قبول التعديل</span>
          </a>
          <a
            v-if="moderation_object.status == 'pending'"
            class="btn btn-outline-danger d-sm-inline-block"
            @click="reject(moderation_object.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-ban"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="9"></circle>
              <line x1="5.7" y1="5.7" x2="18.3" y2="18.3"></line>
            </svg>
            <span class="">رفض</span>
          </a>
        </div>
      </td>
    </tr>
  </transition>

  <tr v-if="finish_mod">
    <td colspan="6" class="p-0">
      <div
        class="card card-sm accordion-collapse collapse"
        :class="{ show: isDropActive }"
        id="edit-view-1"
        role="tabpanel"
        style=""
      >
        <div class="card-status-start bg-blue"></div>
        <div class="card-body">
          <div class="">
            <table class="table table-borderless w-50">
              <thead>
                <tr>
                  <td></td>
                  <td>القيمة السابقة</td>
                  <td class="fw-bold">القيمة الجديدة</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(field, fieldName) in moderation_object.proposed_data"
                  :key="fieldName"
                >
                  <td v-if="fieldName === 'notes' || fieldName === 'modified_by'">
                    <span v-if="field.original != 'None' && field.proposed">{{
                      field.verbose_name
                    }}</span>
                  </td>

                  <td v-else>{{ field.verbose_name }}</td>

                  <template v-if="fieldName === 'photo'">
                    <td>
                      <span class="photo-tag"><img :src="field.original" /></span>
                    </td>
                    <td class="fw-bold">
                      <span class="photo-tag"><img :src="field.proposed" /></span>
                    </td>
                  </template>
                  <template
                    v-else-if="fieldName === 'notes' || fieldName === 'modified_by'"
                  >
                    <template v-if="field.original != 'None' && field.proposed">
                      <td>{{ field.original }}</td>
                      <td class="fw-bold">{{ field.proposed }}</td>
                    </template>
                  </template>

                  <template v-else>
                    <td>{{ field.original }}</td>
                    <td class="fw-bold">{{ field.proposed }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import { formate_datetime } from "@/script.js";

export default {
  props: ["moderation_object"],
  setup() {
    // Accessing axios and global properties
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios;

    // Reactive state
    const finish_mod = ref(true);
    const isDropActive = ref(false);
    const post_method = axios.post;
    const url = "/api/moderations/";
    const item_class = ref(null);
    const form_errors = ref([]);

    // Methods
    const approve = async (id) => {
      if (confirm("الموافقة على التعديلات؟") !== true) return false;
      item_class.value = "bg-blue";

      try {
        await post_method(`${url}${id}/approve/`);
        if (proxy.$store.getters.getHttpError) {
          const error_obj = proxy.$store.getters.getHttpError;
          if (error_obj.response.data) {
            form_errors.value = error_obj.response.data;
          }
        } else {
          finish_mod.value = false;
        }
      } catch (error) {
        console.error("Error approving moderation:", error);
      }
    };

    const reject = async (id) => {
      if (confirm("إلغاء التعديلات؟") !== true) return false;
      item_class.value = "bg-red";

      try {
        await post_method(`${url}${id}/reject/`);
        if (proxy.$store.getters.getHttpError) {
          const error_obj = proxy.$store.getters.getHttpError;
          if (error_obj.response.data) {
            form_errors.value = error_obj.response.data;
          }
        } else {
          finish_mod.value = false;
        }
      } catch (error) {
        console.error("Error rejecting moderation:", error);
      }
    };

    const dropdownToggle = () => {
      isDropActive.value = !isDropActive.value;
    };

    return {
      formate_datetime,
      finish_mod,
      isDropActive,
      item_class,
      approve,
      reject,
      dropdownToggle,
    };
  },
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateY(0);
  opacity: 1;
}

.slide-fade-leave-active {
  transform: translateY(-100%);
  opacity: 0;
}
</style>

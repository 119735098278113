<template>
  <body class="A4" v-if="pin">
    <div
      v-if="!issue.status"
      class="noprint"
      style="
        position: fixed;
        top: 0;
        left: 0;
        background-color: yellow;
        width: 100%;
        height: 50px;
        z-index: 9999;
        text-align: center;
        font-size: xx-large;
      "
    >
      تنبيه: هذا التقرير ملغي
    </div>
    <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
    <section class="sheet padding-10mm">
      <article style="padding-top: 35mm">
        <h2 style="font-size: 20pt; margin: 0; text-align: center">تقرير طبي</h2>
        <hr />
        <table style="width: 100%">
          <tbody>
            <tr>
              <td colspan="2" class="text-bold nowrap font-14">
                <span class="font-15">الاسم: {{ issue.client_data?.name }}</span>
                <br />
                <span class="font-13">تاريخ الميلاد: {{ issue.client_data?.dob }}</span>
                <br />
                <span class="font-13"
                  >تاريخ الفحص: {{ issue.report_data.report_date }}</span
                >
              </td>

              <td style="padding: 0px; direction: ltr" rowspan="4">
                <span
                  style="
                    display: inline-block;
                    background: #eee;
                    display: inline-block;
                    width: 120px;
                    height: 159px;
                    border: 3px solid #000;
                    overflow: hidden;
                  "
                >
                  <img
                    v-if="allow_photo"
                    :src="issue.client_data?.photo"
                    alt="client photo"
                    style="display: block; height: 100%; margin: 0 auto"
                  />
                </span>
                <table
                  style="
                    font-size: 12px;
                    margin-left: 5px;
                    border: 3pt solid #000;
                    font-family: 'Arial';
                    display: inline-block;
                    height: 159px;
                  "
                >
                  <tbody>
                    <tr>
                      <td style="padding: 0">
                        <p style="margin: 0" class="nowrap dir-ltr text-bold">
                          SABERTESTS.COM
                          <br />
                          ID: {{ get_issue_uid(issue_uid) }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 0">
                        <p style="margin: 0" class="nowrap dir-ltr text-bold">
                          PIN: {{ pin }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 0; text-align: center">
                        <!-- <img alt="qr code" :src="issue.qr_code" style="max-width: 2.5cm; max-height: 2.5cm;" /> -->
                        <!-- <img src="/static/qr-code.svg" style="max-width: 2.5cm; max-height: 2.5cm;" /> -->
                        <vue-qrcode
                          type="image/png"
                          :value="issue.qr_code"
                          :color="{ dark: '#000000ff', light: '#ffffffff' }"
                          :height="94.48"
                        ></vue-qrcode>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td class="text-bold font-14 nowrap">
                الجنسية: {{ issue.client_data?.nationality }}
              </td>
              <td class="text-bold font-14 nowrap">
                رقم الجواز: {{ issue.client_data?.passport_no }}
              </td>
            </tr>

            <tr>
              <td class="text-bold font-14 nowrap">
                الحالة الاجتماعية:
                {{ choices.marital_status[issue.client_data?.marital_status] }}
              </td>
              <td class="text-bold font-14 nowrap">
                مكان إصدار الجواز: {{ issue.client_data?.place_of_issue }}
              </td>
            </tr>
            <tr>
              <td class="text-bold font-14 nowrap">
                المهنة: {{ issue.client_data?.occupation }}
              </td>
              <td class="text-bold font-14 nowrap">
                تاريخ إصدار الجواز: {{ issue.client_data?.date_of_issue }}
              </td>
            </tr>
            <tr>
              <td class="text-bold font-14 nowrap">
                رقم الطلب: {{ issue.client_data?.application_no }}
              </td>
              <td colspan="2" class="text-bold font-14 nowrap">
                اسم المكتب: {{ issue.client_data?.office_name }}
              </td>
            </tr>
            <tr>
              <td class="text-bold font-14 nowrap">
                رقم الخطاب: {{ issue.client_data?.letter_no }}
              </td>
              <td colspan="2" class="text-bold font-14 nowrap">
                تاريخ الخطاب: {{ issue.client_data?.letter_date }}
              </td>
            </tr>
          </tbody>
        </table>

        <hr />
        <table class="font-13" style="width: 100%">
          <tbody>
            <tr>
              <td>
                التاريخ المرضي لأي أمراض سابقة مثل: 1.الحساسية والربو
                <template v-if="issue.report_data.history_of_respiratory_diseases"
                  >&#9745;</template
                >
                <template v-else>&#9634;</template>
              </td>
              <td>
                2. الأمراض النفسية والعصبية كالصرع والاكتئاب وغيرها
                <template v-if="issue.report_data.mental_illnesses">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          class="report-details-table font-13"
          border="1"
          cellspacing="0"
          cellpadding="0"
          style="width: 100%"
        >
          <tbody>
            <tr>
              <td colspan="3" style="width: 45%" class="subtitle center-text">
                الفحـص الطــبي الـعـام
              </td>
              <td colspan="4" style="" class="subtitle center-text">
                الفحوصات المخبـريـة
              </td>
            </tr>
            <tr>
              <td rowspan="2" class="center-text">قـوة الإبـــصـار</td>
              <td colspan="2" class="nowrap">
                العين اليمنى: <br />
                {{ choices.normality[issue.report_data.right_eye] }}
              </td>

              <td
                colspan="4"
                rowspan="2"
                class="br-0 left-text nowrap align-top"
                style=""
              >
                <span class="english-font"
                  >General urine examination:
                  {{
                    get_normality_en(issue.report_data.general_urine_examination)
                  }}</span
                >
                <br />
                <span class="english-font"
                  >Sugar: {{ issue.report_data.sugar || "nil" }}</span
                >
                <br />
                <span class="english-font"
                  >Albumin: {{ issue.report_data.albumin || "nil" }}</span
                >
                <br />
                فحص البول العام:
                {{ choices.normality[issue.report_data.general_urine_examination] }}
              </td>
            </tr>
            <tr>
              <td colspan="2" class="nowrap">
                العين اليسرى: <br />
                {{ choices.normality[issue.report_data.left_eye] }}
              </td>
            </tr>
            <tr>
              <td>أمراض العيون الأخرى: {{ issue.report_data.other_eyes_illnesses }}</td>
              <td colspan="2"></td>

              <td colspan="4" rowspan="3" class="br-0 align-top left-text nowrap">
                <span class="english-font"
                  >General stool examination:
                  {{
                    get_normality_en(issue.report_data.general_stool_examination)
                  }}</span
                >
                <br />
                <span class="english-font"
                  >Worms: {{ issue.report_data.worms || "nil" }}</span
                >
                <br />
                <span class="english-font"
                  >Bacteria: {{ issue.report_data.bacteria || "nil" }}</span
                >
                <br />
                <span class="english-font"
                  >V.Cholera: {{ issue.report_data.v_cholera || "nil" }}</span
                >
                <br />
                فحص البراز العام:
                {{ choices.normality[issue.report_data.general_stool_examination] }}
              </td>
            </tr>
            <tr>
              <td style="" class="subtitle center-text">نـــوع الفحـص</td>
              <td style="width: 10%" class="subtitle center-text">طبيعي</td>
              <td style="width: 10%" class="subtitle center-text">غير طبيعي</td>
            </tr>
            <tr>
              <td>قوة السمع</td>
              <td>
                <template v-if="!issue.report_data.hearing">&#10004;</template>
              </td>
              <td>
                <template v-if="issue.report_data.hearing">&#10004;</template>
              </td>
            </tr>
            <tr>
              <td class="nowrap right-text">
                أشعة الصدر <span class="dir-ltr english-font">C.X.R</span>
              </td>
              <td>
                <template v-if="!issue.report_data.chest_xray">&#10004;</template>
              </td>
              <td>
                <template v-if="issue.report_data.chest_xray">&#10004;</template>
              </td>
              <td colspan="4" class="subtitle center-text">
                فحوصات الدم ومصل الدم
                <span class="english-font">Blood &amp; Plasma Tests</span>
              </td>
            </tr>
            <tr style="">
              <td>تخطيط القلب <span class="english-font">ECG</span></td>
              <td>
                <template v-if="!issue.report_data.ecg">&#10004;</template>
              </td>
              <td>
                <template v-if="issue.report_data.ecg">&#10004;</template>
              </td>
              <td>فحص الإيدز</td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Positive
                <template v-if="issue.report_data.hiv">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Negative
                <template v-if="!issue.report_data.hiv">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td colspan="1" class="left-text br-0 dir-ltr english-font">H.I.V</td>
            </tr>
            <tr>
              <td class="nowrap right-text">الفحص السريري للرئتين</td>
              <td>
                <template v-if="!issue.report_data.clinical_examination_of_lungs"
                  >&#10004;</template
                >
              </td>
              <td>
                <template v-if="issue.report_data.clinical_examination_of_lungs"
                  >&#10004;</template
                >
              </td>
              <td class="nowrap right-text">التهاب الكبد <br />الفيروسي ب</td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Positive
                <template v-if="issue.report_data.hbsag">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Negative
                <template v-if="!issue.report_data.hbsag">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td colspan="1" class="left-text br-0 dir-ltr english-font">H.B.S.Ag</td>
            </tr>
            <tr>
              <td>الفحص السريري للبطن</td>
              <td>
                <template v-if="!issue.report_data.abdominal_clinical_examination"
                  >&#10004;</template
                >
              </td>
              <td>
                <template v-if="issue.report_data.abdominal_clinical_examination"
                  >&#10004;</template
                >
              </td>
              <td class="nowrap right-text">
                التهاب الكبد<br />
                الفيروسي ج
              </td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Positive
                <template v-if="issue.report_data.hcv">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Negative
                <template v-if="!issue.report_data.hcv">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td colspan="1" class="left-text br-0 dir-ltr english-font">H.C.V.</td>
            </tr>
            <tr>
              <td class="nowrap right-text">الفحص السريري للأطراف</td>
              <td>
                <template v-if="!issue.report_data.clinical_examination_of_limbs"
                  >&#10004;</template
                >
              </td>
              <td>
                <template v-if="issue.report_data.clinical_examination_of_limbs"
                  >&#10004;</template
                >
              </td>
              <td class="nowrap right-text">
                الالتهابات التناسلية/<br />
                السفلس
              </td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Positive
                <template v-if="issue.report_data.vdrl">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Negative
                <template v-if="!issue.report_data.vdrl">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td colspan="1" class="left-text br-0 dir-ltr english-font">V.D.R.L.</td>
            </tr>
            <tr>
              <td class="nowrap right-text">الفحص السريري للجلد</td>
              <td>
                <template v-if="!issue.report_data.clinical_examination_of_skin"
                  >&#10004;</template
                >
              </td>
              <td>
                <template v-if="issue.report_data.clinical_examination_of_skin"
                  >&#10004;</template
                >
              </td>
              <td>فحص الملاريا</td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Positive
                <template v-if="issue.report_data.mps">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td class="nowrap center-text dir-ltr english-font" style="width: 20pt">
                Negative
                <template v-if="!issue.report_data.mps">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td colspan="1" class="left-text br-0 dir-ltr english-font">M.P.S</td>
            </tr>
            <tr>
              <td>الطول</td>
              <td colspan="2" class="center-text">
                {{ issue.client_data?.last_height || issue.client_data?.height }} سنتيمتر
              </td>
              <td colspan="1" class="nowrap bl-0">نسبة السكر في الدم:</td>
              <td colspan="2" class="bl-0 br-0 dir-ltr english-font center-text">
                {{ issue.report_data.b_sugar }} mg/dl
              </td>
              <td colspan="1" class="left-text br-0 dir-ltr english-font">Bl. sugar:</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="1" class="nowrap bl-0">نسبة اليوريا في الدم:</td>
              <td colspan="2" class="bl-0 br-0 dir-ltr english-font center-text">
                {{ issue.report_data.b_urea }} mg/dl
              </td>
              <td colspan="1" class="left-text br-0 dir-ltr english-font">B. Urea:</td>
            </tr>
            <tr>
              <td colspan="3">
                ضـغط الـدم: {{ issue.report_data.blood_pressure }} ملم زئبقي
              </td>
              <td colspan="1" class="bl-0">نسبة الصفراء في الدم</td>
              <td colspan="2" class="bl-0 br-0 dir-ltr english-font center-text">
                {{ issue.report_data.tbs }} mg/dl
              </td>
              <td colspan="1" class="left-text br-0 dir-ltr english-font">TBS:</td>
            </tr>
            <tr>
              <td colspan="3" rowspan="2">
                ملاحظة: {{ issue.report_data.general_examination_notes }}
              </td>
            </tr>
            <tr>
              <td colspan="4">ملاحظة: {{ issue.report_data.lab_tests_notes }}</td>
            </tr>
          </tbody>
        </table>

        <table class="font-13" style="width: 100%">
          <tbody>
            <tr>
              <td class="subtitle" style="width: 15%">النـتـيـجــــة:</td>
              <td class="subtitle right-text" style="width: 10%">لائـــــــق</td>
              <td style="width: 10%">
                <template v-if="!issue.report_data.result">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td style=""></td>
              <td style=""></td>
              <td style=""></td>
            </tr>
            <tr>
              <td style=""></td>
              <td class="subtitle right-text" style="">غير لائق</td>
              <td style="width: 10%">
                <template v-if="issue.report_data.result">&#9745;</template>
                <template v-else>&#9634;</template>
              </td>
              <td style=""></td>
              <td style=""></td>
              <td style=""></td>
            </tr>
          </tbody>
        </table>
        <table class="font-13" style="width: 100%">
          <tbody>
            <tr>
              <td style="width: 50%" class="subtitle center-text">رئيس قسم المختبر</td>
              <td style="width: 50%" class="subtitle center-text">
                {{ issue.doctor.first_name }} {{ issue.doctor.last_name }}
                <br />
                <!-- {{ issue.doctor.title }} -->
                دكتور
              </td>
            </tr>
          </tbody>
        </table>
      </article>
    </section>
  </body>
</template>

<style scoped>
body {
  margin: 0;
}

.sheet {
  margin: 0;
}

body.A4 .sheet {
  width: 180mm;
  height: auto;
}

/** For screen preview **/

/** Fix for Chrome issue #273306 **/

.A4 {
  /* display: flex; */
  justify-content: center;
  font-family: "Traditional Arabic";
  font-weight: bold;
}

.sheet {
  margin: 0 auto;
}

.A4 hr {
  margin: 0;
}

.center-text {
  text-align: center;
}

.report-details-table td {
  border-width: thin;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.subtitle {
  font-weight: bold;
}

td {
  padding: 0 5pt;
}

.bt-0 {
  border-left: 0;
}

.bb-0 {
  border-bottom: 0;
}

.br-0 {
  border-right: 0;
}

.bl-0 {
  border-left: 0;
}

.nowrap {
  white-space: nowrap;
}

.dir-ltr {
  direction: ltr;
}

.text-bold {
  font-weight: bold;
}

.font-13 {
  font-size: 11pt;
}

.font-14 {
  font-size: 12pt;
}

.font-15 {
  font-size: 14pt;
}
</style>
<script>
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import VueQrcode from "vue-qrcode";
import { get_issue_uid } from "@/script.js";

export default {
  components: { VueQrcode },
  setup() {
    // Accessing global properties
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // Access axios from global properties

    // Reactive state
    const issue = reactive({
      id: "",
      issue_uid: "",
      random_pin: "",
      qr_code: "",
      report_data: {},
      doctor: {},
      created: "",
      status: 1,
    });

    const choices = reactive({
      nationality: "",
      marital_status: "",
      fittness: "",
      positivity: "",
      normality: "",
      status: "",
    });

    const issue_uid = ref(0);
    const pin = ref(0);
    const allow_photo = ref(false);

    // Methods
    const getData = async () => {
      try {
        const response = await axios.get(
          `/api/report-issue/${issue_uid.value}/?pin=${pin.value}`
        );
        const options_response = await axios.get("/api/choices/");

        if (options_response) {
          Object.assign(choices, options_response.data);
        }

        if (response) {
          Object.assign(issue, response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const get_normality_en = (value) => {
      return value ? "Abnormal" : "Normal";
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(() => {
      issue_uid.value = proxy.$route.params.issue_uid;
      pin.value = proxy.$route.params.pin;

      getData();
    });

    return {
      get_issue_uid,
      issue,
      choices,
      issue_uid,
      pin,
      allow_photo,
      getData,
      get_normality_en,
    };
  },
};
</script>

<template>
    <SideBar />
    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                قائمة المكاتب
                            </h2>
                        </div>
                        <!-- Page title actions -->
                        <div class="col-auto ms-auto d-print-none">
                            <div class="btn-list">
                                <router-link to="/office/add/" class="btn btn-primary  d-sm-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    إضافة مكتب جديد
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">

                    <div class="col-12">
                        <div class="card">
                            <SearchBar :dataUrl=dataUrl />
                            <!-- search -->
                            <div v-if="offices.length" class="table-responsive">
                                <table class="table card-table table-vcenter text-nowrap table-hover">
                                    <thead>
                                        <tr>
                                            <th class="w-1">#</th>
                                            <th>اسم المكتب
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-sm text-dark icon-thick" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="6 15 12 9 18 15" />
                                                </svg>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="office in offices" :key="office.id"
                                            :class="{ 'bg-red-lt': office.status == 0 }">
                                            <td><span class="text-muted">{{ office.id }}</span></td>
                                            <td>
                                                {{ office.name }}
                                            </td>


                                            <td class="text-end">
                                                <div class="btn-list flex-nowrap">
                                                    <button
                                                        @click="$router.push({ name: 'edit_office', params: { id: office.id } })"
                                                        class="btn d-sm-inline-block">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="icon icon-tabler icon-tabler-edit" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path
                                                                d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1">
                                                            </path>
                                                            <path
                                                                d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z">
                                                            </path>
                                                            <path d="M16 5l3 3"></path>
                                                        </svg>
                                                        <span class="mx-1">تعديل</span>
                                                    </button>
                                                    <button v-if="office.status == 1"
                                                        @click="change_status(office.id, office.status)"
                                                        class="btn btn-warning d-sm-inline-block">
                                                        <!-- Download SVG icon from http://tabler-icons.io/i/ban -->
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <circle cx="12" cy="12" r="9" />
                                                            <line x1="5.7" y1="5.7" x2="18.3" y2="18.3" />
                                                        </svg>
                                                        <span class="mx-1">تعطيل</span>

                                                    </button>

                                                    <button v-if="office.status == 0"
                                                        @click="change_status(office.id, office.status)"
                                                        class="btn btn-info d-sm-inline-block">
                                                        <!-- Download SVG icon from http://tabler-icons.io/i/check -->
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M5 12l5 5l10 -10" />
                                                        </svg> <span class="mx-1">تفعيل</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- pagination -->
                            <PaginationBar :pagination_data=pagination_data />
                        </div>
                    </div>

                </div>
            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>



<script>
import { ref, onMounted, getCurrentInstance, watch } from 'vue';
import PaginationBar from '@/components/PaginationBar.vue';
import SearchBar from '@/components/SearchBar.vue';
import SideBar from '@/components/SideBar.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
    components: {
        PaginationBar,
        SearchBar,
        SideBar,
        FooterBar,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const axios = proxy.axios; // Access global axios instance

        // Reactive state
        const offices = ref([]);
        const msg = ref('');
        const dataUrl = '/api/office/';
        const pagination_data = ref({});
        const urlArgs = ref('');
        const form_errors = ref([]);

        // Methods
        const change_status = async (id, status) => {
            if (confirm("تغيير حالة المكتب؟") !== true) return false;

            const url = dataUrl + id + '/change_status/';

            try {
                await axios.post(url, { status: !status });

                const index = offices.value.findIndex((item) => item.id === id);
                if (index !== -1) {
                    offices.value[index].status = !status; // Update the specific item
                }

                const error = proxy.$store.getters.getHttpError;
                if (error) {
                    msg.value = error.message;
                    if (error.response?.data) {
                        form_errors.value = error.response.data;
                    }
                }
            } catch (err) {
                console.error("Error changing status:", err);
            }
        };

        const getData = async (url) => {
            try {
                const response = await axios.get(url);

                if (response) {
                    offices.value = response.data.results;
                    pagination_data.value = response.data;

                    const urlParams = new URL(response.request.responseURL);
                    urlArgs.value = urlParams.search;

                    proxy.$router.replace(urlArgs.value, { silent: true });
                }

                const error = proxy.$store.getters.getHttpError;
                if (error) {
                    msg.value = error.message;
                }
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        // Lifecycle hooks
        onMounted(() => {
            urlArgs.value = window.location.search;
            getData(dataUrl + urlArgs.value);
        });

        // Watchers
        watch(
            () => proxy.$route.query,
            (query) => {
                if (Object.keys(query).length === 0) {
                    getData(dataUrl);
                }
            }
        );

        // Return to the template
        return {
            offices,
            msg,
            dataUrl,
            pagination_data,
            urlArgs,
            form_errors,
            change_status,
            getData,
        };
    },
};
</script>

<template>
  <tbody>
    <slot v-for="item in items" :key="item.id" :item="item"></slot>
  </tbody>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';

export default {
  props: {
    id: {
      default: null,
    },
    apiEndpoint: {
      type: String,
      required: true,
    },
    initialOffset: {
      type: Number,
      default: 0,
    },
    container: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance(); // Access global properties
    const items = ref([]);
    const offset = ref(props.initialOffset);
    const loading = ref(false);
    const allLoaded = ref(false);
    const limit = ref(0);

    const attachScrollListener = (container) => {
      container.addEventListener('scroll', handleScroll);
    };

    const handleScroll = () => {
      const scrollContainer = props.container;
      if (
        scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 10 &&
        !loading.value
      ) {
        loadItems();
      }
    };

    const loadItems = async () => {
      if (allLoaded.value || loading.value) return;
      loading.value = true;
      try {
        const response = await proxy.axios.get(props.apiEndpoint, {
          params: {
            offset: offset.value,
            id: props.id,
          },
        });
        const data = response.data;
        if (!limit.value) limit.value = data.results.length;

        items.value = [...items.value, ...data.results];

        if (data.count <= limit.value || data.results.length < limit.value) {
          allLoaded.value = true;
        } else {
          offset.value += limit.value;
        }
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    // Watch container prop for changes
    watch(
      () => props.container,
      (newContainer) => {
        if (newContainer) {
          attachScrollListener(newContainer);
        }
      }
    );

    // Lifecycle hooks
    onMounted(() => {
      if (props.container) {
        attachScrollListener(props.container);
      }
      loadItems();
    });

    onBeforeUnmount(() => {
      if (props.container) {
        props.container.removeEventListener('scroll', handleScroll);
      }
    });

    // Return all reactive data and methods
    return {
      items,
      offset,
      loading,
      allLoaded,
      limit,
      loadItems,
      handleScroll,
    };
  },
};
</script>

<style>
.infinite-scroll-container {
  overflow-y: scroll;
  border: 1px solid #ccc;
}

.loading {
  text-align: center;
  padding: 20px;
}
</style>
<template>

  <router-view :key="$route.fullPath" />
  <notifications position="bottom right" />
  <LoadingIndicator />

</template>
<style>
@import '@/assets/tabler.rtl.css';
</style>

<script>
import { onMounted, getCurrentInstance } from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default {
  components: {
    LoadingIndicator
  },
  setup() {
    // Accessing $i18n using getCurrentInstance
    const { proxy } = getCurrentInstance();

    // Set locale to 'ar'
    onMounted(() => {
      proxy.$i18n.locale = 'ar';
    });

    return {};
  }
};
</script>


<style>
[type="number"] {
  direction: rtl;
}

.flex-nowrap {
  justify-content: flex-end;
}

.dropdown-menu {
  left: 0;
}
</style>
<template>
  <SideBar />

  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 class="page-title">إدارة عمليات التعديل</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="col-12">
            <div class="card">
              <div class="card-body border-bottom py-3">
                <div class="d-flex"></div>
              </div>
              <div class="table-responsive scroll-x">
                <table class="table table-vcenter text-nowrap">
                  <thead>
                    <tr>
                      <th class="text-right">العنصر</th>
                      <th class="text-right">نوع العنصر</th>
                      <th class="text-right">الحالة</th>
                      <th class="text-right">بواسطة</th>
                      <th class="text-right">تاريخ التعديل</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ModerationItem
                      v-for="moderation_object in moderation_objects"
                      :key="moderation_object.id"
                      :moderation_object="moderation_object"
                    />
                  </tbody>
                </table>
              </div>
              <!-- pagination -->
              <PaginationBar :pagination_data="pagination_data" />
            </div>
          </div>
        </div>
      </div>
      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>
<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";
import PaginationBar from "@/components/PaginationBar.vue";
import ModerationItem from "@/components/ModerationItem.vue";

export default {
  components: {
    FooterBar,
    SideBar,
    PaginationBar,
    ModerationItem,
  },
  setup() {
    // Accessing the global instance to get axios, store, and router
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios; // Access axios from global properties
    const store = proxy.$store; // Access Vuex store from global properties

    // Reactive state
    const moderation_objects = ref([]);
    const pagination_data = ref({});
    const urlArgs = ref("");
    const msg = ref("");

    const content_type_id = ref("");
    const object_id = ref("");

    // Methods
    const getData = async (url) => {
      const response = await axios.get(url);
      if (response) {
        moderation_objects.value = response.data.results;
        pagination_data.value = response.data;
        const urlParams = new URL(response.request.responseURL);
        urlArgs.value = urlParams.search;
      }
      if (store.getters.getHttpError) {
        msg.value = store.getters.getHttpError.message;
      }
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(() => {
      urlArgs.value = window.location.search;
      content_type_id.value = proxy.$route.params.content_type_id;
      object_id.value = proxy.$route.params.object_id;

      if (content_type_id.value && object_id.value) {
        getData(
          `/api/moderations/for-content-object/?content_type_id=${content_type_id.value}&object_id=${object_id.value}` +
            urlArgs.value
        );
      } else {
        getData("/api/moderations/" + urlArgs.value);
      }
    });

    // Returning state and methods to the template
    return {
      moderation_objects,
      pagination_data,
      urlArgs,
      msg,
      getData,
    };
  },
};
</script>

<template>
  <SideBar />
  <div class="page">
    <div class="page-wrapper">
      <div class="container-xl">
        <!-- Page title -->
        <div class="page-header d-print-none">
          <div class="row align-items-center">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle"></div>
              <h2 v-if="form.id" class="page-title">تعديل فحص</h2>
              <h2 v-else class="page-title">إضافة فحص جديد</h2>
              <h3 class="card-subtitle m-2">لـ: {{ form.sample_data?.client_name }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="container-xl">
          <div class="row row-cards">
            <div class="col-12">
              <form v-on:submit.prevent="submitForm" class="card">
                <div v-if="form_errors" class="invalid-feedback">
                  {{ form_errors.non_field_errors }}
                </div>
                <div class="card-header">
                  <h4 class="card-title">بيانات الفحص</h4>
                </div>
                <div class="card-body">
                  <div v-if="form?.moderation_status == 0" class="row">
                    <div class="col-12">
                      <div class="alert alert-info alert-dismissible" role="alert">
                        <div class="d-flex">
                          <div>
                            <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                            <!-- SVG icon code with class="alert-icon" -->
                          </div>
                          <div>
                            <h4 class="alert-title">تحت الموافقة</h4>
                            <div class="text-muted">
                              هذا العنصر لازال يتطلب موافقة المدير على التعديلات
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-fieldset">
                    <div class="col-4">
                      <fieldset class="col-12 mb-4">
                        <div class="row divide-y">
                          <div class="col-12 mb-3">
                            <label class="form-label"> كود العينة </label>
                            <input
                              disabled
                              class="form-control"
                              :value="
                                form.height_blood_sample_uid ||
                                form.sample_data?.blood_test_uid
                              "
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div class="col-4">
                      <fieldset class="fieldset col-12">
                        <div class="col-12 col-lg12 mb-4">
                          <label class="form-label">تاريخ الفحص</label>
                          <input
                            autocomplete="off"
                            :disabled="role !== 1"
                            type="date"
                            class="form-control"
                            :class="form_errors.report_date ? 'is-invalid' : ''"
                            v-model="form.report_date"
                          />
                          <div class="invalid-feedback">
                            {{ form_errors.report_date?.[0] }}
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div class="col-4">
                      <fieldset class="fieldset col-12">
                        <div class="col-12 col-lg12 mb-4">
                          <label class="form-label">النتيجة</label>
                          <select
                            class="form-control"
                            :class="form_errors.result ? 'is-invalid' : ''"
                            v-model="form.result"
                          >
                            <option value="0">لائق</option>
                            <option value="1">غير لائق</option>
                          </select>
                          <div class="invalid-feedback">
                            {{ form_errors.result?.[0] }}
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <label class="form-label">الفحـص الطــبي الـعـام</label>
                      <fieldset class="form-fieldset col-12">
                        <div class="row">
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label">العين اليمنى</label>
                            <select
                              class="form-control"
                              :class="form_errors.right_eye ? 'is-invalid' : ''"
                              v-model="form.right_eye"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.right_eye?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label">العين اليسرى</label>
                            <select
                              class="form-control"
                              :class="form_errors.left_eye ? 'is-invalid' : ''"
                              v-model="form.left_eye"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.left_eye?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-12 mb-4">
                            <label class="form-label">امراض العيون الأخرى </label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="
                                form_errors.other_eyes_illnesses ? 'is-invalid' : ''
                              "
                              v-model="form.other_eyes_illnesses"
                            />

                            <div class="invalid-feedback">
                              {{ form_errors.other_eyes_illnesses?.[0] }}
                            </div>
                          </div>

                          <!-- <div class="col-12 col-lg-6 mb-4">
                                                        <label class="form-label"> السمع</label>
                                                        <select class="form-control"
                                                            :class="form_errors.hearing ? 'is-invalid' : ''"
                                                            v-model="form.hearing">
                                                            <option value=0>طبيعي</option>
                                                            <option value=1>غير طبيعي</option>

                                                        </select>
                                                        <div class="invalid-feedback">{{ form_errors.hearing?.[0] }}
                                                        </div>
                                                    </div> -->
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> أشعة الصدر C.X.R </label>
                            <select
                              class="form-control"
                              :class="form_errors.chest_xray ? 'is-invalid' : ''"
                              v-model="form.chest_xray"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.chest_xray?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> تخطيط القلب ECG </label>
                            <select
                              class="form-control"
                              :class="form_errors.ecg ? 'is-invalid' : ''"
                              v-model="form.ecg"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">{{ form_errors.ecg?.[0] }}</div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> قوة السمع</label>
                            <select
                              class="form-control"
                              :class="form_errors.hearing ? 'is-invalid' : ''"
                              v-model="form.hearing"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.hearing?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> الفحص السريري للرئتين</label>
                            <select
                              class="form-control"
                              :class="
                                form_errors.clinical_examination_of_lungs
                                  ? 'is-invalid'
                                  : ''
                              "
                              v-model="form.clinical_examination_of_lungs"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.clinical_examination_of_lungs?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> الفحص السريري للبطن</label>
                            <select
                              class="form-control"
                              :class="
                                form_errors.abdominal_clinical_examination
                                  ? 'is-invalid'
                                  : ''
                              "
                              v-model="form.abdominal_clinical_examination"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.abdominal_clinical_examination?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> الفحص السريري للأطراف</label>
                            <select
                              class="form-control"
                              :class="
                                form_errors.clinical_examination_of_limbs
                                  ? 'is-invalid'
                                  : ''
                              "
                              v-model="form.clinical_examination_of_limbs"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.clinical_examination_of_limbs?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> الفحص السريري للجلد</label>
                            <select
                              class="form-control"
                              :class="
                                form_errors.clinical_examination_of_skin
                                  ? 'is-invalid'
                                  : ''
                              "
                              v-model="form.clinical_examination_of_skin"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.clinical_examination_of_skin?.[0] }}
                            </div>
                          </div>

                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label">ضغط الدم</label>
                            <div class="input-group mb-2">
                              <input
                                autocomplete="off"
                                class="form-control"
                                :class="form_errors.blood_pressure ? 'is-invalid' : ''"
                                v-model="form.blood_pressure"
                              />
                              <span class="input-group-text"> ملم زئبقي </span>
                              <div class="invalid-feedback">
                                {{ form_errors.blood_pressure?.[0] }}
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label">طول العميل</label>
                            <div class="input-group mb-2">
                              <input
                                autocomplete="off"
                                v-if="form.sample_data"
                                disabled
                                class="form-control"
                                v-model="form.sample_data.height"
                              />
                              <span class="input-group-text"> سنتيمتر </span>
                            </div>
                          </div>
                          <hr />
                          <div class="col-12 col-lg-12 mb-4">
                            <label class="form-label">ملاحظة</label>
                            <small class="form-hint">ملاحظات الفحص العام </small>

                            <textarea
                              class="form-control"
                              :class="
                                form_errors.general_examination_notes ? 'is-invalid' : ''
                              "
                              v-model="form.general_examination_notes"
                            />

                            <div class="invalid-feedback">
                              {{ form_errors.general_examination_notes?.[0] }}
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="col-12 col-lg-6">
                      <label class="form-label">التاريخ المرضي لأي أمراض سابقة</label>
                      <fieldset class="form-fieldset col-12">
                        <div class="row">
                          <div class="col-12 col-lg-6">
                            <label class="form-check form-label">
                              <input
                                autocomplete="off"
                                class="form-check-input"
                                type="checkbox"
                                :class="
                                  form_errors.history_of_respiratory_diseases
                                    ? 'is-invalid'
                                    : ''
                                "
                                v-model="form.history_of_respiratory_diseases"
                              />
                              <span class="form-check-label">الحساسية والربو</span>
                            </label>

                            <div class="invalid-feedback">
                              {{ form_errors.history_of_respiratory_diseases }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <label class="form-check form-label">
                              <input
                                autocomplete="off"
                                class="form-check-input"
                                type="checkbox"
                                :class="form_errors.mental_illnesses ? 'is-invalid' : ''"
                                v-model="form.mental_illnesses"
                              />
                              <span class="form-check-label"
                                >الأمراض النفسية والعصبية</span
                              >
                            </label>

                            <div class="invalid-feedback">
                              {{ form_errors.mental_illnesses?.[0] }}
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <label class="form-label">الفحوصات المخبـريـة</label>
                      <fieldset class="form-fieldset col-12">
                        <div class="row">
                          <div class="col-12 col-lg-4 mb-4">
                            <label class="form-label">نسبة السكر في الدم</label>
                            <small class="form-hint">Bl. Sugar</small>

                            <div class="input-group mb-2">
                              <input
                                autocomplete="off"
                                class="form-control"
                                :class="form_errors.b_sugar ? 'is-invalid' : ''"
                                v-model="form.b_sugar"
                              />
                              <span class="input-group-text"> mg/dl </span>
                              <div class="invalid-feedback">
                                {{ form_errors.b_sugar?.[0] }}
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-4 mb-4">
                            <label class="form-label">نسبة اليوريا في الدم</label>
                            <small class="form-hint">B. Urea</small>

                            <div class="input-group mb-2">
                              <input
                                autocomplete="off"
                                class="form-control"
                                :class="form_errors.b_urea ? 'is-invalid' : ''"
                                v-model="form.b_urea"
                              />
                              <span class="input-group-text"> mg/dl </span>
                              <div class="invalid-feedback">
                                {{ form_errors.b_urea?.[0] }}
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-4 mb-4">
                            <label class="form-label">نسبة الصفراء في الدم</label>
                            <small class="form-hint">TBS</small>

                            <div class="input-group mb-2">
                              <input
                                autocomplete="off"
                                class="form-control"
                                :class="form_errors.tbs ? 'is-invalid' : ''"
                                v-model="form.tbs"
                              />
                              <span class="input-group-text"> mg/dl </span>
                              <div class="invalid-feedback">
                                {{ form_errors.tbs?.[0] }}
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-12 mb-4">
                            <label class="form-label">فحص البول العام </label>
                            <select
                              class="form-control"
                              :class="
                                form_errors.general_urine_examination ? 'is-invalid' : ''
                              "
                              v-model="form.general_urine_examination"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.general_urine_examination?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> Sugar </label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.sugar ? 'is-invalid' : ''"
                              v-model="form.sugar"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.sugar?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> Albumin </label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.albumin ? 'is-invalid' : ''"
                              v-model="form.albumin"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.albumin?.[0] }}
                            </div>
                          </div>

                          <div class="col-12 col-lg-12 mb-4">
                            <label class="form-label"> فحص البراز العام</label>
                            <select
                              class="form-control"
                              :class="
                                form_errors.general_stool_examination ? 'is-invalid' : ''
                              "
                              v-model="form.general_stool_examination"
                            >
                              <option value="0">طبيعي</option>
                              <option value="1">غير طبيعي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.general_stool_examination?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-4 mb-4">
                            <label class="form-label"> Worms </label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.worms ? 'is-invalid' : ''"
                              v-model="form.worms"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.worms?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-4 mb-4">
                            <label class="form-label"> Bacteria </label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.bacteria ? 'is-invalid' : ''"
                              v-model="form.bacteria"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.bacteria?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-4 mb-4">
                            <label class="form-label"> V.Cholera </label>
                            <input
                              autocomplete="off"
                              class="form-control"
                              :class="form_errors.v_cholera ? 'is-invalid' : ''"
                              v-model="form.v_cholera"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.v_cholera?.[0] }}
                            </div>
                          </div>
                          <hr />
                          <br />
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> فحص الإيدز</label>
                            <select
                              class="form-control"
                              :class="form_errors.hiv ? 'is-invalid' : ''"
                              v-model="form.hiv"
                            >
                              <option value="0">سلبي</option>
                              <option value="1">ايجابي</option>
                            </select>
                            <div class="invalid-feedback">{{ form_errors.hiv?.[0] }}</div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> التهاب الكبد الفيروسي ب </label>
                            <select
                              class="form-control"
                              :class="form_errors.hbsag ? 'is-invalid' : ''"
                              v-model="form.hbsag"
                            >
                              <option value="0">سلبي</option>
                              <option value="1">ايجابي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.hbsag?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label"> التهاب الكبد الفيروسي ج </label>
                            <select
                              class="form-control"
                              :class="form_errors.hcv ? 'is-invalid' : ''"
                              v-model="form.hcv"
                            >
                              <option value="0">سلبي</option>
                              <option value="1">ايجابي</option>
                            </select>
                            <div class="invalid-feedback">{{ form_errors.hcv?.[0] }}</div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label">
                              الالتهابات التناسلية/ السفلس</label
                            >
                            <select
                              class="form-control"
                              :class="form_errors.vdrl ? 'is-invalid' : ''"
                              v-model="form.vdrl"
                            >
                              <option value="0">سلبي</option>
                              <option value="1">ايجابي</option>
                            </select>
                            <div class="invalid-feedback">
                              {{ form_errors.vdrl?.[0] }}
                            </div>
                          </div>
                          <div class="col-12 col-lg-6 mb-4">
                            <label class="form-label">فحص الملاريا</label>
                            <select
                              class="form-control"
                              :class="form_errors.mps ? 'is-invalid' : ''"
                              v-model="form.mps"
                            >
                              <option value="0">سلبي</option>
                              <option value="1">ايجابي</option>
                            </select>
                            <div class="invalid-feedback">{{ form_errors.mps?.[0] }}</div>
                          </div>

                          <hr />

                          <div class="col-12 col-lg-12 mb-4">
                            <label class="form-label">ملاحظة</label>
                            <small class="form-hint">ملاحظات فحص المختبر</small>

                            <textarea
                              class="form-control"
                              :class="form_errors.lab_tests_notes ? 'is-invalid' : ''"
                              v-model="form.lab_tests_notes"
                            />

                            <div class="invalid-feedback">
                              {{ form_errors.lab_tests_notes?.[0] }}
                            </div>
                          </div>

                          <hr />
                          <br />

                          <div class="col-12">
                            <div class="form-label">ارفاق ملف</div>
                            <input
                              autocomplete="off"
                              ref="attachmentFile"
                              type="file"
                              class="form-control"
                              :class="form_errors.attachment ? 'is-invalid' : ''"
                              @change="editAttachment"
                            />
                            <div class="invalid-feedback">
                              {{ form_errors.attachment?.[0] }}
                            </div>
                            <a
                              v-if="form.attachment && !form.attachment?.name"
                              :href="form.attachment"
                              target="_blank"
                              >{{ form.attachment }}</a
                            >
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div class="card-footer text-end">
                  <div class="d-flex order-reversed">
                    <a href="#" onclick="history.back()" class="btn">إلغاء</a>
                    <button
                      :disabled="!isFormChanged"
                      type="submit"
                      class="btn btn-primary ms-auto"
                    >
                      حفظ
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <FooterBar />
      <!-- footer -->
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import SideBar from "@/components/SideBar.vue";
import * as utilis from "@/script.js";

export default {
  components: {
    FooterBar,
    SideBar,
  },
  setup() {
    // Accessing axios, store, and router
    const { proxy } = getCurrentInstance();
    const axios = proxy.axios;
    const store = proxy.$store;
    const router = proxy.$router;

    // Reactive state
    const form = ref({});
    const nationality_choices = ref([]);
    const marital_status_choices = ref([]);
    const msg = ref("");
    const form_errors = ref({});
    const post_method = ref(axios.post);
    const url = ref(`/api/report/`);
    const initialForm = ref(null);

    // Computed properties
    const role = computed(() => store.getters.getUser.role);

    const isFormChanged = computed(() => {
      if (form.value.id) {
        return JSON.stringify(form.value) !== JSON.stringify(initialForm.value);
      }
      return true;
    });

    // Methods
    const editImage = () => {
      form.value.photo = proxy.$refs.file.files[0];
      console.log(form.value.photo);
    };

    const editAttachment = () => {
      form.value.attachment = proxy.$refs.attachmentFile.files[0];
      console.log(form.value.attachment);
    };

    const submitForm = async () => {
      const headers = { "Content-Type": "multipart/form-data" };
      if (form.value.report_date)
        form.value.report_date = utilis.removeTime(form.value.report_date);
      if (!form.value.attachment?.name) delete form.value.attachment;

      try {
        await post_method.value(url.value, form.value, { headers });
        if (store.getters.getHttpError) {
          let error_obj = store.getters.getHttpError;
          msg.value = error_obj.message;
          if (error_obj.response.data) form_errors.value = error_obj.response.data;
        } else {
          router.replace("/report/");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getData = async () => {
      if (form.value.id) {
        try {
          const response = await axios.get(`/api/report/${form.value.id}/`);
          if (response) form.value = response.data;
          post_method.value = axios.put;
          url.value = `/api/report/${form.value.report_uid}/`;
        } catch (error) {
          console.error(error);
        }
      } else if (form.value.height_blood_sample_uid) {
        try {
          const defaultResponse = await axios.get("/api/report-default-values/");
          const defaultValueObj = defaultResponse.data;
          for (const [key, value] of Object.entries(defaultValueObj)) {
            form.value[key] = value;
          }

          const sampleResponse = await axios.get(
            `/api/sample/${form.value.height_blood_sample_uid}/`
          );
          if (sampleResponse) {
            form.value.report_date = sampleResponse.data.created;
            form.value.sample_data = sampleResponse.data;
          }

          post_method.value = axios.post;
          url.value = `/api/report/`;
        } catch (error) {
          console.error(error);
        }
      }
      if (store.getters.getHttpError) msg.value = store.getters.getHttpError.message;
    };

    // Lifecycle hook equivalent to 'created'
    onMounted(async () => {
      form.value.id = proxy.$route.params.id;
      form.value.height_blood_sample_uid = proxy.$route.params.height_blood_sample_uid;
      await getData();
      initialForm.value = { ...form.value };
    });

    return {
      form,
      nationality_choices,
      marital_status_choices,
      msg,
      form_errors,
      post_method,
      url,
      initialForm,
      role,
      isFormChanged,
      editImage,
      editAttachment,
      submitForm,
      getData,
    };
  },
};
</script>

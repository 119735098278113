<template>

	<body class="A4 ThermalLabel" style="overflow: hidden; padding-top: 0;">
		<!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
		<section style="padding-top: 0;" class="sheet">
			<article style="padding-top: 0;">
				<table style="border:3pt solid #000; margin: 0; font-family: 'Arial'">
					<tbody>
						<tr>
							<td style="padding: 0">
								<p style="font-size: 10pt; margin: 0" class="nowrap dir-ltr text-bold">
									SABERTESTS.COM
									<br />
									ID: {{ get_issue_uid(blood_test_uid) }}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="printPageButton d-print-none">
					<button class="btn" onclick="window.print();return false;">طباعة</button>
					<button class="btn" @click="goBackOrClose">عودة</button>
				</div>
			</article>
		</section>
	</body>
</template>


<style scoped>
.printPageButton {
	height: 0.984in;
}



body {
	width: 1.514in;
}

table {
	text-align: center;
	width: 1.514in;
	height: 0.984in;
}

.A4 {
	display: flex;
	justify-content: center;
	font-family: 'Traditional Arabic';
	font-weight: bold;
}

hr {
	margin: 0;
}

.center-text {
	text-align: center;
}

.left-text {
	text-align: left;
}

.right-text {
	text-align: right;
}

.align-top {
	vertical-align: top;
}

.subtitle {
	font-size: 14pt;
	font-weight: bold;
}

td {
	padding: 0 5pt;
}

.bt-0 {
	border-left: 0;
}

.bb-0 {
	border-bottom: 0;
}

.br-0 {
	border-right: 0;
}

.bl-0 {
	border-left: 0;
}

.nowrap {
	white-space: nowrap;
}

.dir-ltr {
	direction: ltr;
}

.text-bold {
	font-weight: bold;
}

.english-font {
	font-family: 'Times New Roman';
	font-size: 10pt;
}

.font-16 {
	font-size: 16pt;
}

.font-14 {
	font-size: 14pt;
}
</style>
<script>
import { ref, onMounted, getCurrentInstance } from 'vue';
import { get_issue_uid } from '@/script.js';

export default {
	setup() {
		// Accessing axios from global properties
		const { proxy } = getCurrentInstance();
		const axios = proxy.axios;

		// Reactive state
		const blood_test_uid = ref('');
		const id = ref(null);
		const qr_code = ref('');

		// Methods
		const goBackOrClose = () => {
			if (window.history.length > 1) {
				window.history.back();
			} else {
				window.close();
			}
		};

		const getData = async () => {
			const response = await axios.get(`/api/sample/${id.value}/`);
			if (response) {
				blood_test_uid.value = response.data.blood_test_uid.toString();
				qr_code.value = response.data.qr_code;
			}
		};

		// Lifecycle hook
		onMounted(() => {
			id.value = proxy.$route.params.height_blood_sample_uid;
			getData();
		});

		// Returning reactive state and methods to the template
		return {
			get_issue_uid,
			blood_test_uid,
			id,
			qr_code,
			goBackOrClose,
			getData
		};
	}
};
</script>
